import './FaceID.css';
import axios from 'axios';
import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import AlertModal from '../@common/Modal/AlertModal';
import CustomSpinner from '../@common/CustomSpinner/customSpinner';

const FaceID = ({  }) => {
  const search_params = new URLSearchParams(window.location.search);
  const email = search_params.get("email");
  const phone = search_params.get("phone");
  const vlid = search_params.get("vlid");
  const geo_location = search_params.get("geo_location");
  const faceid = search_params.get("faceid");
  const authed = search_params.get("authed");
  
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState('document');
  const [documentData, setDocumentData] = useState(null);
  const [documentDataType, setDocumentDataType] = useState(null);
  const [documentPreview, setDocumentPreview] = useState(null);
  const [selfie, setSelfie] = useState(null);
  const [selfieType, setSelfieType] = useState(null);
  const [isCameraActive, setIsCameraActive] = useState(false);
  const [cameraError, setCameraError] = useState(null);
  const [spinnerVisibility, setSpinnerVisibility] = useState(false);
  const [closedModal, setClosedModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showtext, setShowtext] = useState("");
  const [taskStatus, setTaskStatus] = useState(false);

  const videoRef = useRef(null);
  const fileInputRef = useRef(null);

  const handleClosed = () => {
    setClosedModal(true);
    setShowtext(false);
    
    if (geo_location) {
      navigate("/GeoLocation" + window.location.search);
    } else {
      window.open("https://searchlook.com.mx", "_self");
    }
  }

  // Add useEffect to handle camera initialization
  useEffect(() => {
    let stream = null;

    const initializeCamera = async () => {
      if (isCameraActive && videoRef.current) {
        try {
          const constraints = {
            video: {
              facingMode: currentStep === 'document' ? "environment" : "user",
              width: { ideal: 1280 },
              height: { ideal: 720 }
            },
            audio: false
          };

          stream = await navigator.mediaDevices.getUserMedia(constraints);
          videoRef.current.srcObject = stream;
          
          await new Promise((resolve) => {
            videoRef.current.onloadedmetadata = () => resolve();
          });
          
          await videoRef.current.play();
          setCameraError(null);
        } catch (err) {
          console.error('Camera initialization error:', err);
          setCameraError(err.message || 'Failed to access camera');
          setIsCameraActive(false);
        }
      }
    };

    initializeCamera();

    // Cleanup function
    return () => {
      if (stream) {
        stream.getTracks().forEach(track => track.stop());
      }
    };
  }, [isCameraActive, currentStep]);

  // Simplify startCamera function
  const startCamera = async (isBackCamera = false) => {
    try {
      await stopCamera();
      setIsCameraActive(true);
    } catch (err) {
      console.error('Failed to start camera:', err);
      setCameraError('Failed to start camera');
    }
  };

  // Update stopCamera function
  const stopCamera = () => {
    if (videoRef.current?.srcObject) {
      const tracks = videoRef.current.srcObject.getTracks();
      tracks.forEach(track => track.stop());
      videoRef.current.srcObject = null;
    }
    setIsCameraActive(false);
    setCameraError(null);
  };

  const captureImage = () => {
    if (!videoRef.current) return;

    const canvas = document.createElement('canvas'); // now this will work
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;
    canvas.getContext('2d').drawImage(videoRef.current, 0, 0);
    
    const dataUrl = canvas.toDataURL('image/jpeg');
    
    if (currentStep === 'document') {
      setDocumentData(dataUrl); // updated
      setDocumentDataType('image/jpeg');
      setDocumentPreview(dataUrl);
    } else {
      setSelfie(dataUrl);
      setSelfieType('image/jpeg');
    }
    
    stopCamera();
  };

  // Document handling functions
  const handleDocumentUpload = (event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setDocumentData(file); // updated
    setDocumentDataType(file.type);
    
    if (file.type === 'application/pdf') {
      // For PDFs, create object URL
      setDocumentPreview(URL.createObjectURL(file));
    } else {
      // For images, use FileReader as before
      const reader = new FileReader();
      reader.onload = (e) => {
        setDocumentPreview(e.target?.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Add these new handlers for drag and drop
  const handleDragOver = (e) => {
    e.preventDefault();
    e.currentTarget.classList.add('drag-over');
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.currentTarget.classList.remove('drag-over');
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.currentTarget.classList.remove('drag-over');
    
    const file = e.dataTransfer.files[0];
    if (file && (file.type.startsWith('image/') || file.type === 'application/pdf')) {
      setDocumentData(file); // updated
      setDocumentDataType(file.type);
      
      if (file.type === 'application/pdf') {
        setDocumentPreview(URL.createObjectURL(file));
      } else {
        const reader = new FileReader();
        reader.onload = (e) => {
          setDocumentPreview(e.target.result);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const verifyUser = async (data) => {
    setSpinnerVisibility(true);
    try {
      const response = await axios.post(process.env.REACT_APP_BASE_URL2 + 'face_rec', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if (response.data.similarity>0.5) {
        setTaskStatus(true);
        setShowtext('Successful');
      } else {
        setTaskStatus(false);
        setShowtext('Mismatch');
      }
    } catch (err) {
      console.error(err);
      setTaskStatus(false);
      setShowtext('Encountered an error!');
    } finally {
      setSpinnerVisibility(false);
      setShowAlert(true);
    }
  }

  // Step rendering functions
  const renderDocumentStep = () => (
    <div className="kyc-step">
      <h2>Subir Documento de Identificación</h2>
      <div className="instructions">
        <h3>Documentos Aceptables:</h3>
        <ul>
          <li>Pasaporte válido</li>
          <li>Documento de identidad emitido por el gobierno</li>
          <li>Licencia de conducir</li>
        </ul>
        <h3>Requisitos:</h3>
        <ul>
          <li>El documento debe ser válido y no estar vencido</li>
          <li>Todas las esquinas deben ser visibles</li>
          <li>El texto debe ser claramente legible</li>
          <li>Sin reflejos ni sombras</li>
        </ul>
      </div>
      
      {!isCameraActive && !documentPreview && (
        <div 
          className="document-upload-area"
          onClick={() => fileInputRef.current?.click()}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <div className="upload-icon">📄</div>
          <p>Arrastra y suelta tu documento aquí</p>
          <p>- o -</p>
          <p>Haz clic para elegir un archivo</p>
          <input
            ref={fileInputRef}
            type="file"
            accept="image/*,.pdf"
            onChange={handleDocumentUpload}
            style={{ display: 'none' }}
          />
          {/Mobi|Android|iPhone/i.test(navigator.userAgent) && (
            <button className="button camera-button" onClick={(e) => {
              e.stopPropagation();
              startCamera(true);
            }}>
              📸 Tomar Foto
            </button>
          )}
        </div>
      )}

      {isCameraActive && (
        <div className="camera-view">
          <video 
            ref={videoRef}
            autoPlay 
            playsInline
            muted
          />
          {cameraError && <div className="camera-error">{cameraError}</div>}
          <div className="button-group">
            <button className="button" onClick={captureImage}>Capturar</button>
            <button className="button" onClick={stopCamera}>Cancelar</button>
          </div>
        </div>
      )}

      {documentPreview && (
        <div className="preview-section">
          {documentData?.type === 'application/pdf' ? ( // updated
            <object
              data={documentPreview}
              type="application/pdf"
              className="pdf-preview"
            >
              <p>No se puede mostrar el PDF.<a href={documentPreview} target="_blank" rel="noopener noreferrer">Haz clic aquí para abrirlo</a></p>
            </object>
          ) : (
            <img src={documentPreview} alt="Document preview" />
          )}
          <div className="button-group">
            <button className="button" onClick={() => {
              setDocumentData(null); // updated
              setDocumentDataType(null);
              setDocumentPreview(null);
              // Clean up object URL if it exists
              if (documentData?.type === 'application/pdf') { // updated
                URL.revokeObjectURL(documentPreview);
              }
            }}>Volver a tomar</button>
            <button className="button" onClick={() => setCurrentStep('selfie')}>
            Continuar
            </button>
          </div>
        </div>
      )}
    </div>
  );

  const renderSelfieStep = () => (
    <div className="kyc-step">
      <h2>Tomar una Selfie</h2>
      <div className="instructions">
        <h3>Requisitos para la Selfie:</h3>
        <ul>
          <li>Asegúrate de que tu rostro sea claramente visible</li>
          <li>Mira directamente a la cámara</li>
          <li>Quítate las gafas de sol o sombreros</li>
          <li>Utiliza buena iluminación</li>
        </ul>
      </div>
      
      {!isCameraActive && !selfie && (
        <div className="start-camera-container">
          <button 
            className="button"
            onClick={() => {
              startCamera(false);
            }}
          >
            Iniciar Cámara
          </button>
        </div>
      )}

      {isCameraActive && (
        <div className="selfie-container">
          <div className="selfie-frame">
            <div className="camera-view">
              <video 
                ref={videoRef}
                autoPlay 
                playsInline
                muted
                style={{ transform: 'scaleX(-1)' }}
              />
              {cameraError && <div className="camera-error">{cameraError}</div>}
            </div>
          </div>
          <div className="button-group">
            <button className="button" onClick={captureImage}>Capturar</button>
            <button className="button" onClick={stopCamera}>Cancelar</button>
          </div>
        </div>
      )}

      {selfie && (
        <div className="preview-section">
          <img src={selfie} alt="Vista previa de selfie" />
          <div className="button-group">
            <button className="button" onClick={() => {setSelfie(null); setSelfieType(null)}}>Volver a tomar</button>
            <button 
              className="button"
              onClick={() => verifyUser({ documentDataType, documentData, selfieType, selfie, vlid })}
            >
              Enviar
            </button>
          </div>
        </div>
      )}
    </div>
  );

  return (
    <>
      {showAlert && <AlertModal closedModal={closedModal} thanks={taskStatus} closedModalFunction={handleClosed} text={showtext} onClose={handleClosed} />}
      <CustomSpinner visible={spinnerVisibility} />
      <div className="kyc-container">
        {currentStep === 'document' ? renderDocumentStep() : renderSelfieStep()}
      </div>
    </>
  );
};

export default FaceID;
