import { Grid, Typography, Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import underLine from "../../assets/images/under-line.png";
import ReportHeader from "../@common/ReportHeader/ReportHeader";
import AccordainTable from "../@common/AccordainTable/AccordainTable";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
// HELPER FUNCTIONS
import { useTranslation } from "react-i18next";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import { getCookiesList } from "../../utils/cookiesHelper";
import {
  translationHelper,
  translateNestedDict,
} from "../../utils/bureauHelper";
import LogoModalLoader from "../@common/Modal/WithBureauModal/LogoModalLoader.js";
import AlertModal from "../@common/Modal/AlertModal";
import FloatingBar from '../@common/FloatingBar/FloatingBar';
import { verReport, checkCupons } from "../../utils/bureauHelper";
import ReportEmailSendModal from "../@common/Modal/ReportEmailSendModal.js";
import axios from "axios";

function Report() {
  const { t, i18n } = useTranslation();
  const { personalization, espData, sub_sections_data, vlid, curp, usuarioCupon } =
    useLocation().state;
  const [cookieList, setCookieList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailModal, setIsEmailModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showText, setShowText] = useState("");
  const [currentReport, setCurrentReport] = useState(
    i18n.language === "es" ? espData : translateNestedDict(espData)
  );

  const navigate = useNavigate();

  const announcementHandler = () => {
    navigate("/dashboard");
  };

  useEffect(() => {
    const userCookiesList = getCookiesList("userInfo");
    if (userCookiesList.length > 0) {
      setCookieList(userCookiesList);
    }
  }, []);

  useEffect(
    () => async () => {
      if (i18n.language === "es") {
        setCurrentReport(espData);
      }
      if (i18n.language === "en") {
        setCurrentReport(translateNestedDict(espData));
      }
    },
    [i18n.language]
  );

  const openModal = (flag, errorMsg) => {
    setShowAlert(flag);
    setShowText(errorMsg);
  };

  const resendReport = async () => {
    setIsLoading(true);
    let queryParams = `vlid=${vlid}&curp=${curp}&email=${encodeURIComponent(
      cookieList[3]
    )}&type=${usuarioCupon}&function=resend&lang=${
      localStorage.getItem("selectedLanguage") || "es"
    }&update=false`;
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL2 + "consult?" + queryParams
      );
      if (response.data == "not found") {
        setIsLoading(false);
        openModal(true, t("thanks.NoUpdatedDocument"));
      } else if (response.data == "error bd") {
        setIsLoading(false);
        openModal(true, "Error Occured");
      } else if (response.data == "ok") {
        verReport(vlid);
        checkCupons(usuarioCupon, cookieList[3]);
        setIsLoading(false);
        setIsEmailModal(true);
      }
    } catch (error) {
      setIsLoading(false);
      openModal(true, t("thanks.check"));
    }
  };

  const downloadReport = async () => {
    setIsLoading(true);
    let queryParams = `vlid=${vlid}&curp=${curp}&email=${encodeURIComponent(
      cookieList[3]
    )}&type=${usuarioCupon}&function=download&lang=${
      localStorage.getItem("selectedLanguage") || "es"
    }&update=false`;
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL2 + "consult?" + queryParams,
        { responseType: "arraybuffer" }
      );
      if (response.data == "not found") {
        setIsLoading(false);
        openModal(true, t("thanks.NoUpdatedDocument"));
      } else {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        const timeZone = "America/Mexico_City"; // Replace with the correct time zone identifier
        const currentDate = new Date();
        const formatter = new Intl.DateTimeFormat("en", {
          timeZone,
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        });
        const formattedDate = formatter.format(currentDate).replace(/:/g, "-");

        const filename = `${curp}_${formattedDate}.pdf`;
        a.download = filename;

        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        window.URL.revokeObjectURL(url);

        verReport(vlid);
        checkCupons(usuarioCupon, cookieList[3]);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      openModal(true, "Some error occured");
    }
  };

  return (
    <>
      {showAlert && (
        <AlertModal text={showText} onClose={() => setShowAlert(false)} />
      )}
      {isEmailModal && (
        <ReportEmailSendModal
          open={isEmailModal}
          onClose={() => setIsEmailModal(false)}
        />
      )}
      <LogoModalLoader isOpenLogoModal={isLoading} />
      <FloatingBar
        resendReport={resendReport}
        downloadReport={downloadReport}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Card>
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  // justifyContent: "center",
                  alignItems: "flex-start", // or alignSelf: "flex-start"
                  textAlign: "center",
                }}
              >
                {currentReport.logo ? (
                  <img height={52} src={currentReport.logo} alt="logo report" />
                ) : (
                  <img
                    height={52}
                    src="https://searchlook-new.s3.us-east-1.amazonaws.com/compartir/logoSearchlookGarangou.png"
                    alt="logo report"
                  />
                )}
              </Box>

            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12}>
          <TableContainer sx={{ borderRadius: 0 }} component={Paper}>
            <Table sx={{ borderRadius: 0 }} aria-label="simple table">
              <TableRow>
                <TableCell
                  align="left"
                  sx={{ width: "100%", p: 0, border: "solid 1px #f4f4f4" }}
                >
                  <ReportHeader
                    Age={currentReport["Edad"]}
                    Curp={currentReport["CURP"]}
                    RFC={currentReport["RFC"]}
                    Name={currentReport["nombre"]}
                    Date={currentReport["Fecha_Consulta"]}
                    NSS={currentReport["NSS"]}
                    Total_Homonimos={currentReport["Total_Homonimos"]}
                    Face_ID_Exists={currentReport["Face_ID_Exists"]}
                    Face_ID_Status={currentReport["Face_ID_Status"]}
                    Face_ID_Color={currentReport["Face_ID_Color"]}
                  />
                </TableCell>
              </TableRow>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Card sx={{ border: 0 }}>
            <CardContent sx={{ border: 0, p: 0 }}>
              <TableContainer
                sx={{ borderRadius: 0, backgroundColor: "transparent" }}
                component={Paper}
              >
                <Table
                  sx={{
                    borderRadius: 0,
                    backgroundColor: "transparent",
                  }}
                  aria-label="simple table"
                >
                  <TableRow sx={{ backgroundColor: "transparent" }}>
                    <TableCell
                      align="left"
                      sx={{ width: "100%", backgroundColor: "transparent" }}
                    >
                      <AccordainTable
                        personalization={personalization}
                        reportData={currentReport}
                        sub_dicts={sub_sections_data}
                      />
                    </TableCell>
                  </TableRow>
                </Table>
              </TableContainer>

              <Box sx={{ display: "flex", justifyContent: "center", mt: 6 }}>
                <Button
                  onClick={announcementHandler}
                  sx={{
                    backgroundColor: "#4165F6",
                    color: "#ffffff",
                    fontSize: 18,
                    fontWeight: 600,
                    px: 6,
                  }}
                  variant="contained"
                >
                  {t("Report_sec_h.textQueryButton")}
                </Button>
              </Box>
              <Box
                sx={{
                  backgroundColor: "rgba(0, 127, 255, 0.4)",
                  px: 6,
                  py: 4,
                  borderRadius: "10px",
                  mt: 6,
                  mx: 1,
                }}
              >
                <Box
                  sx={{ display: "flex", justifyContent: "center", mb: 1.8 }}
                >
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontSize: 18,
                      color: "#4165F6",
                      fontWeight: 600,
                    }}
                  >
                    {t("Report_sec_h.belowtextQueryButton")}
                  </Typography>
                </Box>
                <ul>
                  <li style={{ fontSize: 16, marginBottom: "10px" }}>
                    {t("Report_sec_h.imp1")}
                  </li>
                  <li style={{ fontSize: 16 }}>{t("Report_sec_h.imp2")}</li>
                </ul>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default Report;
