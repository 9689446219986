import React, { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import downloadIcon from "../../../../assets/images/downloadIcon.svg";
import { useTranslation } from "react-i18next";
import {
  DataGrid,
  GridCellEditStopReasons,
  GridCellModes,
  GridEditInputCell,
  GridToolbar,
  heIL,
} from "@mui/x-data-grid";
import underLine from "../../../../assets/images/under-line.png";
import bulkDocument from "../../../../assets/images/bulkDocument.svg";
import addBtn from "../../../../assets/images/addBtn.svg";
import { Grid, MenuItem, Select, TextField } from "@mui/material";
import moment from "moment/moment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { options_edo } from "../../../../utils/optionsEdo";
import DocUploadModal from "./DocUploadModal";
import { getCookiesList } from "../../../../utils/cookiesHelper";
import SuccessfullModal from "../SuccessfullModal";
import AlertModal from "../AlertModal";
import LogoModalLoader from "./LogoModalLoader";
import axios from "axios";
import ResetConfirmModal from "../ResetConfirmModal";
import DownloadIcon from "@mui/icons-material/Download";
import * as XLSX from "xlsx";
import trashimage from "../../../../assets/images/Iconly-Bold-Delete.svg";

const MakeBulkModal = (props) => {
  const importFileInputRef = useRef(null);
  const { t, i18n } = useTranslation();
  // const [open, setOpen] = useState(false);
  const [cookieList, setCookieList] = useState([]);
  const [docOpen, setDocOpen] = useState(false);
  const [rowModesModel, setRowModesModel] = useState({});
  const [cellModesModel, setCellModesModel] = React.useState({});
  console.log(cellModesModel, "cellModesModel");
  const [rowNbc, setRowNbc] = useState("");

  options_edo[0].name = t("placeholders.ddEdonto");

  const [isLogoModalOpen, setLogoModalOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false); // Add a state to control the visibility of the alert
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [showtext, setShowtext] = useState("");
  const [resetConfirm, setResetConfirm] = useState(false);

  const style = {
    "*::-webkit-scrollbar": {
      width: "6px !important",
      borderRadius: "3px !important",
      height: "6px !important",
    },
    "*::-webkit-scrollbar-track": {
      backgroundColor: "#E4EFEF !important",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "#4165f1d4 !important",
      borderRadius: "3px !important",
    },
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "95%",
    maxWidth: { sm: "95%", xs: "95%" },
    maxHeight: "90vh",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "20px",
    overflowY: "auto",
  };

  //single click
  const handleCellClick = React.useCallback((params, event) => {
    console.log(params, "params");
    if (!params.isEditable) {
      return;
    }

    // Ignore portal
    if (
      event.target.nodeType === 1 &&
      !event.currentTarget.contains(event.target)
    ) {
      return;
    }

    setCellModesModel((prevModel) => {
      return {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View },
              }),
              {}
            ),
          }),
          {}
        ),
        [params.id]: {
          // Revert the mode of other cells in the same row
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
            {}
          ),
          [params.field]: { mode: GridCellModes.Edit },
        },
      };
    });
  }, []);

  // Logo modal Handlers
  const handleOpenLogoModal = () => {
    setLogoModalOpen(true);
  };

  const handleCloseLogoModal = () => {
    setLogoModalOpen(false);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const handleClose = () => {
    setRows(initialRows);
    setValidationErrors([]);
    setRowModesModel({});
    props.closeModal();
  };

  const initialRows = [
    {
      id: 0,
      curp: "",
      Nombre: "",
      Paterno: "",
      Sexo: t("labels.radioLabelMale"),
      FechaNacimiento: "DD-MM-YYYY",
      EdoNacimiento: t("placeholders.ddEdonto"),
      FiltroLegal: t("labels.inputLabelYes"),
      EmailCandidato: "",
      TipoConsulta: "CB",
      PersonaPresente: t("labels.inputLabelYes"),
      CodigoPostal: "",
      Calle: "",
      Colonia: "",
      AlcaldiaMunicipio: "",
      TelefonoCandidato: "",
      Estado: t("placeholders.ddEdonto"),
      documents: [],
      Materno: "",
      ssn: "",
      professional: "",
      NumExt: "",
      infonavit: "",
      infonavitPassword: "",
      certificate: "",
      revalidation: "",
      Zone: "",
      Branch: "",
      // motherLastName: "",
      // ssn: "",
      // sep: "",
      // candidateDoc: t("labels.inputLabelYes"),
      // state: "State",
      // privacyAccept: t("labels.inputLabelYes"),
      // authorization: t("labels.inputLabelYes"),
      // documents: []
    },
  ];

  const [rows, setRows] = useState(initialRows);
  const rowsRef = useRef(rows);
  console.log(rows, "rows");
  const [validationErrors, setValidationErrors] = useState([]);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [uploadDoc, setUploadDoc] = useState();

  useEffect(() => {
    rowsRef.current = rows; // Sync ref with state
  }, [rows]);

  const validateRows = (rows) => {
    const errors = [];
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^[0-9]{10}$/;
    const postalCodePattern = /^[0-9]{5}$/;
    const curpPattern = /^[A-Z0-9]{18}$/;
    const numberPattern = /^[0-9]{11}$/;

    // Refactored validations

    rows.forEach((row, index) => {
      const isSB = row.TipoConsulta === "SB";
      const isCB = row.TipoConsulta === "CB";
      const hasCurp = row.curp;

      console.log("Validated rows", rows);

      const ssnString = String(row.ssn); //for ssn
      if (ssnString && row.ssn) {
        const ssnParts = ssnString.split(",");
        if (ssnParts.length > 4) {
          errors.push({ index, message: "thanks.ssnErrorUpload" });
        }
        ssnParts.map((part) => {
          if (!numberPattern.test(part)) {
            errors.push({ index, message: "thanks.ssnErrorUpload" });
          }
        });
      }

      if (row.infonavit && !numberPattern.test(row.infonavit)) {
        //for infonavit
        errors.push({ index, message: "consultField.infonavitError" });
      }

      if (!row.EmailCandidato || !emailPattern.test(row.EmailCandidato)) {
        errors.push({ index, message: t("consultField.emailReq") });
      }

      if (hasCurp) {
        // Validate fields for SB if CURP exists
        const trimmedCurp = String(row.curp).trim(); // Trim any hidden characters or spaces
        if (!curpPattern.test(trimmedCurp)) {
          errors.push({ index, message: t("consultField.curpvalid") });
        }
      } else {
        // Validate fields for SB if CURP does not exist
        if (!row.Nombre) {
          errors.push({ index, message: t("consultField.nameValid") });
        }
        if (!row.Paterno) {
          errors.push({ index, message: t("consultField.lastValid") });
        }
        if (row.FechaNacimiento === "DD-MM-YYYY") {
          errors.push({
            index,
            message: t("consultField.birthDateValidFormat"),
          });
        }
        if (!row.Sexo) {
          errors.push({ index, message: t("consultField.sexValid") });
        }
        if (!row.EdoNacimiento || row.EdoNacimiento === "State") {
          errors.push({ index, message: t("consultField.stateOfBirthValid") });
        }
      }

      if (isCB) {
        // CB / Con buró validation
        if (!row.Calle) {
          errors.push({ index, message: t("consultField.StreetValid") });
        }
        if (!row.Colonia) {
          errors.push({ index, message: t("consultField.ColonyValid") });
        }
        if (!row.AlcaldiaMunicipio) {
          errors.push({ index, message: t("consultField.MunicipalityValid") });
        }
        if (!postalCodePattern.test(row.CodigoPostal)) {
          errors.push({ index, message: t("consultField.postalCodeValid") });
        }
        if (!row.Estado || row.Estado === "State") {
          errors.push({ index, message: t("consultField.state") });
        }
        if (
          !row.TelefonoCandidato ||
          !phonePattern.test(row.TelefonoCandidato)
        ) {
          errors.push({
            index,
            message: t("consultField.candidatePhoneValid"),
          });
        }
      }
    });

    // rows.forEach((row, index) => {
    //   const isSB = row.multiReport === "SB";
    //   const isCB = row.multiReport === "CB";
    //   const hasCurp = row.curp;

    //   const ssnString = String(row.ssn); //for ssn
    //   if (ssnString && row.ssn) {
    //     const ssnParts = ssnString.split(",");
    //     if (ssnParts.length > 4) {
    //       errors.push({ index, message: 'thanks.ssnErrorUpload' });
    //     }
    //     ssnParts.map((part) => {
    //       if (!numberPattern.test(part)) {
    //         errors.push({ index, message: 'thanks.ssnErrorUpload' });
    //       }
    //     });
    //   }

    //   if (row.infonavit && !numberPattern.test(row.infonavit)) {      //for infonavit
    //     errors.push({ index, message: 'consultField.infonavitError' });
    //   }

    //   if (isSB) {
    //     // SB / Sin buró validation
    //     if (hasCurp) {
    //       // Validate fields for SB if CURP exists
    //       if (!curpPattern.test(row.curp)) {
    //         errors.push({ index, message: t("consultField.curpvalid") });
    //       }
    //       if (!row.activeLegalFilter) {
    //         errors.push({ index, message: t("consultField.activeLegalValid") });
    //       }
    //       if (row.multiReport !== "SB") {
    //         errors.push({ index, message: t("consultField.multiReportValid") });
    //       }
    //       if (!row.PersonaPresente) {
    //         errors.push({ index, message: t("consultField.PersonaPresenteValid") });
    //       }
    //       if (!row.emailSent || !emailPattern.test(row.emailSent)) {
    //         errors.push({ index, message: t("consultField.emailReq") });
    //       }
    //     } else {
    //       // Validate fields for SB if CURP does not exist
    //       if (!row.name) {
    //         errors.push({ index, message: t("consultField.nameValid") });
    //       }
    //       if (!row.lastName) {
    //         errors.push({ index, message: t("consultField.lastValid") });
    //       }
    //       if (!row.sex) {
    //         errors.push({ index, message: t("consultField.sexValid") });
    //       }
    //       if (row.birthDate === "DD-MM-YYYY") {
    //         errors.push({ index, message: t("consultField.birthDateValidFormat") });
    //       }
    //       if (!row.stateOfBirth || row.stateOfBirth === "State") {
    //         errors.push({ index, message: t("consultField.stateOfBirthValid") });
    //       }
    //       if (!row.activeLegalFilter) {
    //         errors.push({ index, message: t("consultField.activeLegalValid") });
    //       }
    //       if (row.multiReport !== "SB") {
    //         errors.push({ index, message: t("consultField.multiReportValid") });
    //       }
    //       if (!row.PersonaPresente) {
    //         errors.push({ index, message: t("consultField.PersonaPresenteValid") });
    //       }
    //       if (!row.emailSent || !emailPattern.test(row.emailSent)) {
    //         errors.push({ index, message: t("consultField.emailReq") });
    //       }
    //     }
    //   } else if (isCB) {
    //     // CB / Con buró validation
    //     if (hasCurp) {
    //       // Validate fields for CB if CURP exists
    //       if (!curpPattern.test(row.curp)) {
    //         errors.push({ index, message: t("consultField.curpvalid") });
    //       }
    //       if (!row.activeLegalFilter) {
    //         errors.push({ index, message: t("consultField.activeLegalValid") });
    //       }
    //       if (row.multiReport !== "CB") {
    //         errors.push({ index, message: t("consultField.multiReportValid") });
    //       }
    //       if (!row.PersonaPresente) {
    //         errors.push({ index, message: t("consultField.PersonaPresenteValid") });
    //       }
    //       if (!row.emailSent || !emailPattern.test(row.emailSent)) {
    //         errors.push({ index, message: t("consultField.emailReq") });
    //       }
    //       if (!phonePattern.test(row.candidatePhone)) {
    //         errors.push({ index, message: t("consultField.candidatePhoneValid") });
    //       }
    //       if (!row.street) {
    //         errors.push({ index, message: t("consultField.StreetValid") });
    //       }
    //       if (!row.Colony) {
    //         errors.push({ index, message: t("consultField.ColonyValid") });
    //       }
    //       if (!row.Municipality) {
    //         errors.push({ index, message: t("consultField.MunicipalityValid") });
    //       }
    //       if (!postalCodePattern.test(row.postalCode)) {
    //         errors.push({ index, message: t("consultField.postalCodeValid") });
    //       }
    //       if (!row.State || row.State === "State") {
    //         errors.push({ index, message: t("consultField.state") });
    //       }
    //     } else {
    //       // Validate fields for CB if CURP does not exist
    //       if (!row.name) {
    //         errors.push({ index, message: t("consultField.nameValid") });
    //       }
    //       if (!row.lastName) {
    //         errors.push({ index, message: t("consultField.lastValid") });
    //       }
    //       if (!row.sex) {
    //         errors.push({ index, message: t("consultField.sexValid") });
    //       }
    //       if (row.birthDate === "DD-MM-YYYY") {
    //         errors.push({ index, message: t("consultField.birthDateValidFormat") });
    //       }
    //       if (!row.stateOfBirth || row.stateOfBirth === "State") {
    //         errors.push({ index, message: t("consultField.stateOfBirthValid") });
    //       }
    //       if (!row.activeLegalFilter) {
    //         errors.push({ index, message: t("consultField.activeLegalValid") });
    //       }
    //       if (row.multiReport !== "CB") {
    //         errors.push({ index, message: t("consultField.multiReportValid") });
    //       }
    //       if (!row.PersonaPresente) {
    //         errors.push({ index, message: t("consultField.PersonaPresenteValid") });
    //       }
    //       if (!row.emailSent || !emailPattern.test(row.emailSent)) {
    //         errors.push({ index, message: t("consultField.emailReq") });
    //       }
    //       if (!phonePattern.test(row.candidatePhone)) {
    //         errors.push({ index, message: t("consultField.candidatePhoneValid") });
    //       }
    //       if (!row.street) {
    //         errors.push({ index, message: t("consultField.StreetValid") });
    //       }
    //       if (!row.Colony) {
    //         errors.push({ index, message: t("consultField.ColonyValid") });
    //       }
    //       if (!row.Municipality) {
    //         errors.push({ index, message: t("consultField.MunicipalityValid") });
    //       }
    //       if (!postalCodePattern.test(row.postalCode)) {
    //         errors.push({ index, message: t("consultField.postalCodeValid") });
    //       }
    //       if (!row.State || row.State === "State") {
    //         errors.push({ index, message: t("consultField.state") });
    //       }
    //     }
    //   }
    // });

    return errors;
  };

  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const handleAddMore = async () => {
    await sleep(1000);

    const updatedRows = rowsRef.current.map((row) => ({
      ...row,
      Sexo:
        row.Sexo === "H"
          ? t("labels.radioLabelMale") // Ensure this uses translation
          : row.Sexo === "Mujer"
          ? t("labels.radioLabelFemale")
          : row.Sexo === "Hombre"
          ? t("labels.radioLabelMale")
          : row.Sexo === "M"
          ? t("labels.radioLabelFemale")
          : row.Sexo, // Keep the current value if none of the above conditions match

      FiltroLegal:
        row.FiltroLegal === "Sí" || row.FiltroLegal === "SÍ"
          ? t("labels.inputLabelYes")
          : row.FiltroLegal === "No"
          ? t("labels.inputLabelNo")
          : row.FiltroLegal,

      TipoConsulta:
        row.TipoConsulta === "Sin buró"
          ? "SB"
          : row.TipoConsulta === "Con Buró"
          ? "CB"
          : row.TipoConsulta,

      PersonaPresente:
        row.PersonaPresente === "SÍ" || row.PersonaPresente === "Sí"
          ? t("labels.inputLabelYes")
          : row.PersonaPresente,
    }));

    const errors = validateRows(updatedRows);
    if (errors.length > 0) {
      setValidationErrors(errors);
      return;
    } else {
      setValidationErrors([]);
    }

    const newRow = {
      id: updatedRows.length + 1,
      curp: "",
      Nombre: "",
      Paterno: "",
      Sexo: t("labels.radioLabelMale"),
      FechaNacimiento: "DD-MM-YYYY",
      EdoNacimiento: t("placeholders.ddEdonto"),
      FiltroLegal: t("labels.inputLabelYes"),
      EmailCandidato: "",
      TipoConsulta: "CB",
      PersonaPresente: t("labels.inputLabelYes"),
      CodigoPostal: "",
      Calle: "",
      Colonia: "",
      AlcaldiaMunicipio: "",
      TelefonoCandidato: "",
      Estado: t("placeholders.ddEdonto"),
      documents: [],
      Materno: "",
      ssn: "",
      professional: "",
      NumExt: "",
      infonavit: "",
      infonavitPassword: "",
      certificate: "",
      revalidation: "",
      Zone: "",
      Branch: "",
    };
    setRows([...updatedRows, newRow]);
  };

  const handleReset = () => {
    setRows(initialRows);
    setValidationErrors([]);
    setRowModesModel({});
  };

  const handleProcessRowUpdate = (newRow) => {
    // Fields to skip for uppercase transformation
    // console.log("newRow", newRow);
    const excludeUppercaseFields = [
      "EmailCandidato",
      "Sexo",
      "EdoNacimiento",
      "FiltroLegal",
      "TipoConsulta",
      "PersonaPresente",
      "Estado",
      "nbc_id",
    ];

    // Create a copy of newRow and apply uppercase transformation where appropriate
    const updatedRow = { ...newRow };

    Object.keys(updatedRow).forEach((field) => {
      if (
        !excludeUppercaseFields.includes(field) &&
        typeof updatedRow[field] === "string"
      ) {
        updatedRow[field] = updatedRow[field].toUpperCase();
      }
    });

    // Update the rows with the transformed data
    const updatedRows = rows.map((row) =>
      row.id === updatedRow.id ? updatedRow : row
    );
    console.log(updatedRows, "=======updatedRows");
    console.log("Date:", new Date());

    setRows(updatedRows);

    return updatedRow;
  };

  const handleSubmit = async () => {
    await sleep(1000);
    // Update fields based on specific conditions

    //   const updatedRows = rowsRef.current.map((row) => ({
    //     ...row,
    //     Sexo:
    //       row.Sexo === "H"
    //         ? "male"
    //         : row.Sexo === "Mujer"
    //         ? "female"
    //         : row.Sexo === "Hombre"
    //         ? "male"
    //         : row.Sexo === "M"
    //         ? "female"
    //         : row.Sexo, // Keep the current value if none of the above conditions match

    //     FiltroLegal: row.FiltroLegal === "Sí" ? "Yes" : row.FiltroLegal, // Check for "Sí" and map to "Yes"

    //     TipoConsulta:
    //       row.TipoConsulta === "Sin buró"
    //         ? "SB"
    //         : row.TipoConsulta === "Con Buró"
    //         ? "CB"
    //         : row.TipoConsulta,

    //     PersonaPresente:
    //       row.PersonaPresente === "SÍ" || row.PersonaPresente === "Sí"
    //         ? "Yes"
    //         : row.PersonaPresente,

    //     FiltroLegal:
    //       row.FiltroLegal === "SÍ" || row.FiltroLegal === "Sí"
    //         ? "Yes"
    //         : row.FiltroLegal,
    //   }
    // ));

    // // Update fields based on specific conditions
    const updatedRows = rowsRef.current.map((row) => {
      const updatedRow = {
        ...row,
        Sexo:
          row.Sexo === "H"
            ? "male"
            : row.Sexo === "Mujer"
            ? "female"
            : row.Sexo === "Hombre"
            ? "male"
            : row.Sexo === "M"
            ? "female"
            : row.Sexo, // Keep the current value if none of the above conditions match

        FiltroLegal: row.FiltroLegal === "Sí" ? "Yes" : row.FiltroLegal, // Check for "Sí" and map to "Yes"

        TipoConsulta:
          row.TipoConsulta === "Sin buró"
            ? "SB"
            : row.TipoConsulta === "Con Buró"
            ? "CB"
            : row.TipoConsulta,

        PersonaPresente:
          row.PersonaPresente === "SÍ" || row.PersonaPresente === "Sí"
            ? "Yes"
            : row.PersonaPresente,

        FiltroLegal:
          row.FiltroLegal === "SÍ" || row.FiltroLegal === "Sí"
            ? "Yes"
            : row.FiltroLegal,
      };

      // Define unnecessary fields and placeholder values
      const unnecessaryValues = [
        "",
        "DD-MM-YYYY", // Value representing empty date
        t("placeholders.ddEdonto"), // Placeholder translation for "Estado"
        []
      ];

      // Remove empty, unnecessary, or unwanted fields
      const cleanedRow = Object.fromEntries(
        Object.entries(updatedRow).filter(([key, value]) => {
          // Remove fields that are empty, match unnecessary values, or are unwanted
          const isUnwantedValue = unnecessaryValues.includes(value)|| (Array.isArray(value) && value.length === 0);;
          const isEmpty =
            value === null || value === undefined || isUnwantedValue;
          return !isEmpty; // Filter out fields with empty or unwanted values
        })
      );

      return cleanedRow; // Return the cleaned row
    });

    const errors = validateRows(updatedRows);

    if (errors.length > 0) {
      setValidationErrors(errors);
      return;
    }

    setValidationErrors([]);
    handleOpenLogoModal();
    const endpoint = process.env.REACT_APP_BASE_URL2 + "update_csv";
    const params = {
      userName: cookieList[0],
      userLastname: cookieList[1],
      userSurlastname: cookieList[2],
      userEmail: cookieList[3],
      userPhone: cookieList[5],
      userId: cookieList[6],
      table_data: updatedRows,
    };
    try {
      const response = await axios.post(endpoint, params, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response?.data) {
        handleCloseLogoModal();
        setIsSuccessModalOpen(true);
        // handleClose();
      } else if (response?.data?.error) {
        handleCloseLogoModal();
        setShowtext(response?.data?.error);
        setShowAlert(true);
      }
    } catch (error) {
      handleCloseLogoModal();
      setShowAlert(true);
      setShowtext(t("GuestDashboard.consultPopUpMessageDataError"));
    }
  };

  const handleOpenDocModal = (row) => {
    setSelectedRowId(row.id);
    setUploadDoc(row.documents || []);
    setDocOpen(true);
    setRowNbc(row?.nbc_Id || "");
  };

  const handleSaveDocuments = (files, nbcId) => {
    setRows((prevRows) =>
      prevRows.map((row) =>
        row.id === selectedRowId
          ? {
              ...row,
              documents: files,
              nbc_Id: nbcId,
            }
          : row
      )
    );
    setDocOpen(false);
    setRowNbc("");
  };

  const renderSelectEditCell = (options) => (params) =>
    (
      <Select
        value={params.value}
        onChange={(event) => {
          console.log(event, params, "apaaaaaaaaaaaaaaa");
          params.api.setEditCellValue({
            id: params.id,
            field: params.field,
            value: event.target.value,
          });
        }}
        sx={{ width: "100%" }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    );

  const renderSelectPlaceEditCell = (options) => (params) =>
    (
      <Select
        value={params.value || ""}
        onChange={(event) =>
          params.api.setEditCellValue({
            id: params.id,
            field: params.field,
            value: event.target.value,
          })
        }
        sx={{ width: "100%" }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    );

  const renderDateEditCell = (params) => (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        value={params.value ? moment(params.value, "DD-MM-YYYY") : null}
        onChange={(newValue) => {
          params.api.setEditCellValue({
            id: params.id,
            field: params.field,
            value: newValue ? moment(newValue).format("DD-MM-YYYY") : "",
          });
        }}
        format="DD-MM-YYYY"
        renderInput={(props) => (
          <TextField
            {...props}
            sx={{ width: "100%" }}
            value={
              params.value ? moment(params.value).format("DD-MM-YYYY") : ""
            }
          />
        )}
      />
    </LocalizationProvider>
  );

  const handleDeleteRow = (id) => {
    if (rows.length > 1) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const columns = [
    {
      field: "curp",
      headerName: t("labels.inputLabelCurp"),
      width: 180,
      editable: true,
      renderEditCell: (params) => (
        <GridEditInputCell
          {...params}
          inputProps={{
            maxLength: 18, // Restrict the input to a maximum of 18 characters
          }}
        />
      ),
    },
    {
      field: "documents",
      headerName: t("labels.documents"),
      width: 150,
      editable: false,
      renderCell: (params) => (
        <Box sx={{ width: "100%", textAlign: "center" }}>
          <img
            src={bulkDocument}
            alt="Document"
            style={{ cursor: "pointer" }}
            onClick={() => handleOpenDocModal(params.row)}
          />
        </Box>
      ),
    },
    {
      field: "Nombre",
      headerName: t("labels.inputLabelName"),
      width: 110,
      editable: true,
    },
    {
      field: "Paterno",
      headerName: t("labels.inputLabelLastName"),
      width: 110,
      editable: true,
    },
    {
      field: "Materno",
      headerName: t("labels.inputLabelMotherName"),
      width: 180,
      editable: true,
    },
    {
      field: "Sexo",
      headerName: t("labels.topLabelSex"),
      width: 100,
      editable: true,
      renderEditCell: renderSelectEditCell([
        {
          value: t("labels.radioLabelMale"),
          label: t("labels.radioLabelMale"),
        },
        {
          value: t("labels.radioLabelFemale"),
          label: t("labels.radioLabelFemale"),
        },
      ]),
    },
    {
      field: "FechaNacimiento",
      headerName: t("labels.inputLabelBirthDate"),
      width: 200,
      editable: true,
      renderEditCell: renderDateEditCell,
    },
    {
      field: "ssn",
      headerName: t("labels.inputLabelSnsOptional"),
      width: 150,
      editable: true,
    },
    {
      field: "professional",
      headerName: t("labels.inputLabelIdOptional"),
      width: 250,
      editable: true,
    },
    {
      field: "EdoNacimiento",
      headerName: t("labels.inputLabelEdoNto"),
      width: 150,
      editable: true,
      renderEditCell: renderSelectPlaceEditCell(options_edo),
    },
    {
      field: "FiltroLegal",
      headerName: t("labels.inputActiveLegalFilter"),
      width: 180,
      editable: true,
      renderEditCell: renderSelectEditCell([
        { value: t("labels.inputLabelYes"), label: t("labels.inputLabelYes") },
        { value: t("labels.inputLabelNo"), label: t("labels.inputLabelNo") },
      ]),
    },
    {
      field: "TipoConsulta",
      headerName: t("labels.inputMultiReportType"),
      width: 180,
      editable: true,
      renderEditCell: renderSelectEditCell([
        { value: "CB", label: "CB" },
        { value: "SB", label: "SB" },
      ]),
    },
    {
      field: "PersonaPresente",
      headerName: t("consultField.PersonPresent"),
      width: 180,
      editable: true,
      renderEditCell: renderSelectEditCell([
        { value: t("labels.inputLabelYes"), label: t("labels.inputLabelYes") },
        { value: t("labels.inputLabelNo"), label: t("labels.inputLabelNo") },
      ]),
    },
    {
      field: "EmailCandidato",
      headerName: t("labels.emailSent"),
      width: 180,
      editable: true,
    },
    {
      field: "TelefonoCandidato",
      headerName: t("consultField.CandidatePhone"),
      width: 180,
      editable: true,
      renderEditCell: (params) => (
        <GridEditInputCell
          {...params}
          inputProps={{
            maxLength: 10, // Restrict the input to a maximum of 18 characters
          }}
        />
      ),
    },
    {
      field: "Calle",
      headerName: t("labels.inputLabelStreet"),
      width: 180,
      editable: true,
    },
    {
      field: "NumExt",
      headerName: t("consultField.NumExt"),
      width: 180,
      editable: true,
    },
    {
      field: "Colonia",
      headerName: t("labels.inputLabelCologne"),
      width: 180,
      editable: true,
    },
    {
      field: "AlcaldiaMunicipio",
      headerName: t("labels.inputLabelMavorsOffice"),
      width: 180,
      editable: true,
    },
    {
      field: "CodigoPostal",
      headerName: t("labels.inputLabelPostalCode"),
      width: 180,
      editable: true,
    },
    {
      field: "Estado",
      headerName: t("placeholders.ddEdonto"),
      width: 150,
      editable: true,
      renderEditCell: renderSelectPlaceEditCell(options_edo),
    },
    {
      field: "infonavit",
      headerName: t("labels.InfonavitAccount(Optional)"),
      width: 250,
      editable: true,
    },
    {
      field: "infonavitPassword",
      headerName: t("consultField.infoPassword"),
      width: 250,
      editable: true,
      renderEditCell: (params) => (
        <TextField
          type="password"
          value={params.value || ""}
          onChange={(event) =>
            params.api.setEditCellValue({
              id: params.id,
              field: params.field,
              value: event.target.value,
            })
          }
          fullWidth
        />
      ),
    },
    {
      field: "certificate",
      headerName: t("consultField.certificate"),
      width: 250,
      editable: true,
    },
    {
      field: "revalidation",
      headerName: t("consultField.revalidation"),
      width: 250,
      editable: true,
    },
    {
      field: "Zone",
      headerName: t("consultField.Zone"),
      width: 250,
      editable: true,
    },
    {
      field: "Branch",
      headerName: t("consultField.Branch"),
      width: 250,
      editable: true,
    },
    {
      field: "delete",
      headerName: t("authorizationpanel.rightContent.subtitle.Delete"),
      width: 100,
      editable: false,
      renderCell: (params) => (
        <Box sx={{ width: "100%", textAlign: "center" }}>
          <Button
            variant="contained"
            color="error"
            onClick={() => handleDeleteRow(params.id)}
            sx={{
              background: "transparent !important",
              p: 0,
              minWidth: "20px",
            }}
          >
            {rows.length > 1 ? (
              <img
                src={trashimage}
                alt="Delete"
                style={{ width: "20px", height: "20px" }}
              />
            ) : (
              <img
                src={trashimage}
                alt="Delete"
                style={{
                  width: "20px",
                  height: "20px",
                  filter:
                    "invert(74%) sepia(4%) saturate(6%) hue-rotate(88deg) brightness(89%) contrast(93%)",
                  cursor: "not-allowed",
                }}
              />
            )}
          </Button>
        </Box>
      ),
    },
    // {
    //   field: 'privacyAccept',
    //   headerName: t("labels.privacyAccept"),
    //   width: 180,
    //   editable: true,
    //   renderEditCell: renderSelectEditCell([
    //     { value: t("labels.inputLabelYes"), label: t("labels.inputLabelYes") },
    //     { value: t("labels.inputLabelNo"), label: t("labels.inputLabelNo") }
    //   ])
    // },
    // {
    //   field: 'authorization',
    //   headerName: t("labels.authorization"),
    //   width: 180,
    //   editable: true,
    //   renderEditCell: renderSelectEditCell([
    //     { value: t("labels.inputLabelYes"), label: t("labels.inputLabelYes") },
    //     { value: t("labels.inputLabelNo"), label: t("labels.inputLabelNo") }
    //   ])
    // },
    // {
    //   field: 'sep',
    //   headerName: t("labels.SEPFOLIO(Optional)"),
    //   width: 180,
    //   editable: true
    // },
    // {
    //   field: 'candidateDoc',
    //   headerName: t("labels.CandidateDocumentation(Optional)"),
    //   width: 250,
    //   editable: true,
    //   renderEditCell: renderSelectEditCell([
    //     { value: t("labels.inputLabelYes"), label: t("labels.inputLabelYes") },
    //     { value: t("labels.inputLabelNo"), label: t("labels.inputLabelNo") }
    //   ])
    // },
  ];

  useEffect(() => {
    const userCookiesList = getCookiesList("userInfo");

    if (userCookiesList.length > 0) {
      setCookieList(userCookiesList);
    }
  }, []);

  const handleRowEditStart = (params, event) => {
    console.log("handleRowEditStart:params, event", params, event);
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    console.log("handleRowEditStop:params, event", params, event);
    event.defaultMuiPrevented = true;
  };

  // Step 2: Function to trigger file input
  const handleImportClick = () => {
    importFileInputRef.current.click(); // Trigger the hidden file input
  };

  const handleImportFileChange = (event) => {
    const file = event.target.files[0]; // Get the selected file
    console.log("Selected file:", file); // Do something with the file
    if (file) {
      const fileType = file.name.split(".").pop(); // Get file extension

      if (fileType === "csv") {
        handleCSVFile(file); // Process CSV file
      } else if (fileType === "xls" || fileType === "xlsx") {
        handleExcelFile(file); // Process Excel file
      }
    }
    event.target.value = null;
  };

  const handleCSVFile = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target.result;
      const rows = text.split("\n").map((row) => row.split(",")); // Basic CSV parsing
      // let headerRow = rows[0];
      rows.splice(0, 1);

      let formatedRows = [];
      const lastRowId = rows.length > 0 ? rows[rows.length - 1].id : 0; // Get the last row's id
      let index = lastRowId + 1; // Set the next id based on the last id
      // let index = 1;
      for (let rowData of rows) {
        if (
          typeof rowData[1] === "undefined" ||
          typeof rowData[2] === "undefined" ||
          typeof rowData[3] === "undefined"
        ) {
          continue;
        }

        formatedRows.push({
          id: index,
          curp: rowData[0],
          Nombre: rowData[1],
          Paterno: rowData[2],
          Materno: rowData[3],
          Sexo: rowData[4],
          FechaNacimiento: rowData[5],
          EdoNacimiento: rowData[6],
          ssn: rowData[7],
          professional: rowData[8],
          infonavit: rowData[9],
          sep: rowData[10],
          candidateDoc: rowData[11],
          FiltroLegal: rowData[12],
          EmailCandidato: rowData[13],
          TipoConsulta: rowData[14],
          privacyAccept: rowData[15],
          authorization: rowData[16],
        });
        index++;
      }
      const firstRowIncomplete =
        !rows[0]?.curp ||
        !rows[0]?.name ||
        !rows[0]?.Paterno ||
        !rows[0]?.Materno;

      if (formatedRows?.length) {
        // setRows(formatedRows);
        // setRows(prevRows => [...prevRows, ...formatedRows]);
        setRows((prevRows) => {
          if (firstRowIncomplete) {
            // Overwrite the first row if incomplete
            return [...formatedRows, ...prevRows.slice(1)];
          } else {
            // Append the new rows if the first row is complete
            return [...prevRows, ...formatedRows];
          }
        });
      }
    };
    reader.readAsText(file); // Read the CSV file as text
  };

  const handleExcelFile = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0]; // Get the first sheet
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet); // Convert sheet to JSON
      console.log("Parsed Excel Data:", jsonData); // Debug log

      const dateFormatRegex = /^\d{2}\/\d{2}\/\d{4}$/; // Regex to match DD/MM/YYYY format

      const convertExcelDate = (excelSerial) => {
        if (typeof excelSerial !== "number" || excelSerial <= 0) {
          console.warn("Invalid serial date:", excelSerial); // Debug log
          return "";
        }

        // Excel's date system starts from 1900-01-01 (serial number 1)
        const excelStartDate = new Date(Date.UTC(1900, 0, 1)); // Excel start date: Jan 1, 1900

        // Excel has a bug in handling leap years (1900 was not a leap year, but Excel considers it as such)
        // The workaround is to subtract 2 if the serial number is greater than 60.
        const adjustedSerial = excelSerial - (excelSerial >= 60 ? 2 : 1);

        // Convert serial number to milliseconds (days * hours * minutes * seconds * milliseconds)
        const dateInMilliseconds = adjustedSerial * 24 * 60 * 60 * 1000;

        // Create JavaScript Date object from the milliseconds
        const jsDate = new Date(excelStartDate.getTime() + dateInMilliseconds);

        // Format the date to DD/MM/YYYY
        const day = jsDate.getUTCDate().toString().padStart(2, "0");
        const month = (jsDate.getUTCMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed
        const year = jsDate.getUTCFullYear();

        return `${day}/${month}/${year}`;
      };

      let formatedRows = [];
      const lastRowId = rows?.length > 0 ? rows[rows.length - 1].id : 0; // Get the last row's id
      let index = lastRowId + 1; // Set the next id based on the last id

      for (let rowData of jsonData) {
        let dateOfBirth = "";
        console.log(typeof rowData?.FechaNacimiento, "birth");

        // Handle the birthdate field, checking if it's an Excel serial date or a formatted date string
        if (rowData.FechaNacimiento) {
          if (
            typeof rowData.FechaNacimiento === "string" &&
            dateFormatRegex.test(rowData.FechaNacimiento)
          ) {
            // If the birthdate is already a valid formatted date (e.g., "DD/MM/YYYY")
            dateOfBirth = rowData.FechaNacimiento;
          } else if (
            typeof rowData.FechaNacimiento === "number" ||
            !isNaN(Number(rowData.FechaNacimiento))
          ) {
            // If the birthdate is a number (Excel serial date) or can be converted to a number
            const formattedDate = convertExcelDate(
              Number(rowData.FechaNacimiento)
            );
            console.log("Converted Date:", formattedDate); // Debug log
            if (formattedDate) {
              dateOfBirth = formattedDate;
            }
          }
        }

        formatedRows.push({
          id: index,
          curp: rowData.curp || rowData.CURP || "",
          Nombre: rowData.name || rowData.Nombre || "",
          Paterno: rowData.last_name || rowData.Paterno || "",
          Materno: rowData.mother_last_name || rowData.Materno || "",
          Sexo: rowData.sex || rowData.Sexo || t("labels.radioLabelMale"),
          FechaNacimiento:
            dateOfBirth || rowData.FechaNacimiento || "DD-MM-YYYY",
          EdoNacimiento:
            rowData.state_of_birth ||
            rowData.EdoNacimiento ||
            t("placeholders.ddEdonto"),
          ssn: rowData.ssn || rowData.NSS || rowData["NSS (Opcional)"] || "",
          professional:
            rowData.professional_id_number ||
            rowData.Cedula ||
            rowData["Cedula (Opcional)"],
          infonavit:
            rowData.infonavit_account ||
            rowData["NoCuentaInfonavit (Opcional)"] ||
            "",
          sep: rowData.sep_folio || "",
          candidateDoc: rowData.candidate_additional_data || "",
          FiltroLegal:
            rowData.activate_legal_filter ||
            rowData.FiltroLegal ||
            t("labels.inputLabelYes"),
          EmailCandidato:
            rowData.email_to_be_sent || rowData.EmailCandidato || "",
          TipoConsulta:
            rowData.multi_report_type || rowData.TipoConsulta || "CB",
          privacyAccept: rowData.privacy_acceptance || "",
          authorization: rowData.authorization || "",
          PersonaPresente: rowData.PersonaPresente || t("labels.inputLabelYes"),
          CodigoPostal: rowData.CodigoPostal || "",
          Calle: rowData.Calle || "",
          Colonia: rowData.Colonia || "",
          AlcaldiaMunicipio: rowData.AlcaldiaMunicipio || "",
          TelefonoCandidato: rowData.TelefonoCandidato || "",
          Estado: rowData.Estado || t("placeholders.ddEdonto"),
          NumExt: rowData.NumExt || "",
          infonavitPassword: rowData["ContraseñaInfonavit (Opcional)"] || "",
          certificate: rowData["SIGEDCertificado (Opcional)"] || "",
          revalidation: rowData["SIGEDRevalidacion (Opcional)"] || "",
          Zone: rowData["Zona (Opcional)"] || "",
          Branch: rowData["Sucursal (Opcional)"] || "",
        });
        index++;
      }

      const firstRowIncomplete =
        !rows[0]?.curp || !rows[0]?.Nombre || !rows[0]?.Paterno;

      if (formatedRows?.length) {
        setRows((prevRows) => {
          if (firstRowIncomplete) {
            // Overwrite the first row if incomplete
            return [...formatedRows, ...prevRows.slice(1)];
          } else {
            // Append the new rows if the first row is complete
            return [...prevRows, ...formatedRows];
          }
        });
      }
    };
    reader.readAsArrayBuffer(file); // Read Excel file as ArrayBuffer
  };

  const handleCellModesModelChange = React.useCallback((newModel) => {
    setCellModesModel(newModel);
  }, []);

  const handleCellEditStop = (params, event) => {
    console.log("Edit stopped:", params, event);
    const { id, field, value } = params;
    const { key } = event;

    setRows((prev) => {
      const rowsCopy = [...prev];

      const selected = { ...rowsCopy[id] };
      console.log("beofre", selected[field]);
      selected[field] = value + key;
      console.log("after", selected[field]);

      rowsCopy[id] = selected;
      return rowsCopy;
    });
  };

  return (
    <React.Fragment>
      <SuccessfullModal
        isOpen={isSuccessModalOpen}
        setIsOpen={setIsSuccessModalOpen}
        onSuccessClose={handleClose}
      />
      <ResetConfirmModal
        isOpen={resetConfirm}
        setIsOpen={setResetConfirm}
        handleReset={handleReset}
      />

      {showAlert && <AlertModal text={showtext} onClose={handleCloseAlert} />}

      <LogoModalLoader isOpenLogoModal={isLogoModalOpen} />

      <DocUploadModal
        docOpen={docOpen}
        setDocOpen={setDocOpen}
        onSaveDocuments={handleSaveDocuments}
        documents={uploadDoc}
        rowNbc={rowNbc}
      />

      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              position: "fixed",
              top: "16px",
              right: "16px",
            }}
          >
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Grid item xs={12} sm={12} sx={{ marginBottom: "20px" }}>
            <Box sx={{ mt: { xs: 2, sm: 1 } }}>
              <Typography
                sx={{
                  fontFamily: "Poppins-SemiBold",
                  fontSize: { xs: "24px", sm: "26px" },
                  lineHeight: "35px",
                  textAlign: "center",
                  color: "#1C212D",
                }}
              >
                {t("BulkUpload.MakeBulkUpload")}
              </Typography>
              <Box sx={{ textAlign: "center", mt: -1 }}>
                <img src={underLine} alt="under line" />
              </Box>
            </Box>
          </Grid>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Button
              // onClick={() => { i18n.language == 'en' ? window.location.href = '/bulk-upload-sample-en.xlsx' : window.location.href = '/bulk-upload-sample-es.xlsx' }}
              onClick={() => {
                window.location.href = "/bulk-upload-sample-es.xlsx";
              }}
              sx={{
                mb: 2,
                backgroundColor: "Transparent !important",
                textAlign: "center",
                borderRadius: "8px",
                py: { sm: 1.2, xs: 1 },
                px: { sm: 5, xs: 3 },
                color: "#FF8D28",
                textDecoration: "none",
                fontSize: { sm: 16, xs: 14 },
                fontFamily: "Gilroy-Medium",
                textTransform: "none",
                textDecoration: "underline !important",
                textUnderlineOffset: "3px",
              }}
            >
              {t("consultField.downloadSampleFile")}
            </Button>

            <Button
              variant="contained"
              onClick={handleImportClick} // Step 4: Trigger the file picker
              sx={{
                mb: 2,
                backgroundColor: " rgba(255, 141, 0, 0.1) !important",
                textAlign: "center",
                borderRadius: "8px",
                py: { sm: 1.2, xs: 1 },
                px: { sm: 3, xs: 3 },
                color: "#FF8D28",
                textDecoration: "none",
                fontSize: { sm: 18, xs: 16 },
                fontFamily: "Gilroy-Medium",
                textTransform: "capitalize",
              }}
            >
              <DownloadIcon
                sx={{
                  fontSize: "1.3rem",
                  marginRight: "5px",
                }}
              />
              {t("buttons.Import")}
            </Button>

            {/* Hidden file input */}
            <input
              type="file"
              ref={importFileInputRef}
              style={{ display: "none" }} // Hide the input element
              accept=".xls, .xlsx" // Accept only CSV and Excel files
              onChange={handleImportFileChange} // Handle file selection
            />
          </Box>
          <Box
            sx={{
              maxHeight: "50vh",
              overflowY: "auto",
              width: "100%",
              paddingRight: "8px",
            }}
          >
            <DataGrid
              experimentalFeatures={{ newEditingApi: true }}
              rows={rows}
              columns={columns}
              processRowUpdate={handleProcessRowUpdate}
              rowModesModel={rowModesModel}
              onRowEditStart={handleRowEditStart}
              onRowEditStop={handleRowEditStop}
              onCellEditStart={(...args) =>
                console.log(args, "onCellEditStart")
              }
              // onCellEditStop={(params, event) => {
              //   if (params.reason === GridCellEditStopReasons.cellFocusOut) {
              //     event.defaultMuiPrevented = true;
              //   }
              // }}
              // onCellEditStop={handleCellEditStop}

              // onCellKeyDown={handleCellEditStop}
              onEditCellChange={(params) => {
                console.log(params.value, "Real-time value");
                // You can update the row state or perform actions based on the new value
              }}
              onCellEditCommit={(...args) =>
                console.log(args, "onCellEditCommit")
              }
              onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
              autoHeight // Disable field editing
              initialState={{
                pagination: false, // Disable pagination
              }}
              checkboxSelection={false}
              disableRowSelectionOnClick
              hideFooterPagination={true}
              disableColumnMenu={true} // disable the filter functionality
              cellModesModel={cellModesModel}
              onCellModesModelChange={handleCellModesModelChange}
              onCellClick={handleCellClick}
              sx={{
                "& .MuiDataGrid-root .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted):hover .MuiDataGrid-sortIcon ":
                  {
                    opacity: "1",
                  },

                "& svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall.MuiDataGrid-sortIcon.MuiSvgIcon-root":
                  {
                    color: "#fff",
                  },
                " & .MuiDataGrid-columnHeaderRow :nth-child(1 of svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSvgIcon-root)":
                  {
                    fill: "#fff",
                  },
                "& .MuiDataGrid-columnSeparator.MuiDataGrid-columnSeparator--sideRight  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSvgIcon-root":
                  {
                    display: "none",
                  },
                border: "solid 1px #f4f4f4",
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "#ff8d28",
                  borderTopRightRadius: 4,
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  fontSize: 18,
                  color: "#fff",
                  fontFamily: "Gilroy-SemiBold",
                },
                "& .MuiDataGrid-cellContent": {
                  fontSize: 16,
                  color: "rgba(28, 33, 45, 1)",
                  fontFamily: "Gilroy-Medium",
                },
                "& .MuiDataGrid-footerContainer": {
                  display: "none",
                },
                "& .MuiDataGrid-withBorderColor": {
                  borderColor: "#f4f4f4 !important",
                },
                "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus":
                  {
                    outline: "none !important", // Remove the outline
                  },
                "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within":
                  {
                    outline: "none !important",
                  },
                "& .MuiDataGrid-selectedRowCount": {
                  display: "none !important",
                },
                width: "100%",
                "& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input.MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                  {
                    paddingTop: "11px",
                    paddingBottom: "11px",
                  },
                "& .MuiInputBase-input-MuiOutlinedInput-input ": {
                  padding: "11px 14px !important",
                },
                "& .MuiInputBase-root-MuiDataGrid-editInputCell ": {
                  border: "1px solid #E6E8F0",
                  borderRadius: "8px",
                  // margin: "5px",
                  padding: "13px",
                  marginRight: "5px",
                },
                ".MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline ":
                  {
                    borderColor: "#E6E8F0",
                  },
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "#E6E8F0 !important",
                },
                ".MuiInputBase-root-MuiDataGrid-editInputCell input":
                  {
                    padding: "0",
                  },
                "& .MuiInputBase-root-MuiOutlinedInput-root": {
                  marginRight: "5px",
                },
                ".MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root ":
                  {
                    marginRight: "5px",
                  },
              }}
            />
          </Box>
          <Box>
            {validationErrors.length > 0 && (
              <Box sx={{ marginBottom: "16px", color: "red" }}>
                {validationErrors.map((error, index) => (
                  <div key={index}>
                    Row {error.index + 1}: {t(error.message)}
                  </div>
                ))}
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "24px",
              flexWrap: "wrap",
            }}
          >
            <Button
              onClick={() => {
                setResetConfirm(true);
              }}
              sx={{
                mt: 2,
                backgroundColor: "Transparent !important",
                textAlign: "center",
                borderRadius: "8px",
                py: { sm: 1.2, xs: 1 },
                px: { sm: 5, xs: 3 },
                color: "#FF8D28",
                textDecoration: "none",
                fontSize: { sm: 18, xs: 16 },
                fontFamily: "Gilroy-Medium",
                textTransform: "capitalize",
                textDecoration: "underline !important",
                textUnderlineOffset: "3px",
              }}
            >
              {t("consultField.reset")}
            </Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              sx={{
                mt: 2,
                backgroundColor: "primary.main",
                textAlign: "center",
                borderRadius: "8px",
                py: { sm: 1.2, xs: 1 },
                px: { sm: 5, xs: 3 },
                color: "#fff",
                textDecoration: "none",
                fontSize: { sm: 18, xs: 16 },
                fontFamily: "Gilroy-Medium",
                textTransform: "capitalize",
              }}
            >
              {t("consultField.submit")}
            </Button>
            <Button
              onClick={() => {
                handleAddMore();
              }}
              startIcon={
                <img
                  src={addBtn}
                  alt="Add Icon"
                  style={{ width: 24, height: 24 }}
                />
              }
              sx={{
                mt: 2,
                backgroundColor: "Transparent !important",
                textAlign: "center",
                borderRadius: "8px",
                py: { sm: 1.2, xs: 1 },
                px: { sm: 5, xs: 3 },
                color: "#FF8D28",
                textDecoration: "none",
                fontSize: { sm: 18, xs: 16 },
                fontFamily: "Gilroy-Medium",
                textTransform: "capitalize",
              }}
            >
              {t("consultField.addMore")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default MakeBulkModal;
