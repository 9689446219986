import React from 'react';
import { Dialog, DialogTitle, DialogContent, Typography } from '@mui/material';
import { useTranslation } from "react-i18next";

const TrackingPopup = ({ open }) => {
  const { t } = useTranslation();
  return (
    <Dialog open={open}>
      <DialogTitle>
        <Typography variant="h6">{t("labels.locationTrackingActive")}</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>
          {t("labels.locationTrackingMessage")}
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default TrackingPopup;
