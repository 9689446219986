import { Alert, Box, Button, Checkbox, FormControlLabel, FormHelperText, Grid, MenuItem, Tooltip, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import underLine from "../../assets/images/under-line.png";
import phoneIcon from "../../assets/images/phoneIcon.png";
import mailImage from "../../assets/images/mailIcon.png"
import LabelInputLeftIcon from '../@common/Input/LabelInputLeftIcon';
import LabelInput from '../@common/Input/LabelInput';
import PasswordShowHideIcon from '../@common/Input/PasswordShowHideIcon/PasswordShowHideIcon';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import consultUpload from "../../../src/assets/images/consultUpload.svg";
import previewFile from "../../../src/assets/images/previewFile.svg";
import deleteFile from "../../../src/assets/images/deletefile.svg"
import pdfImage from "../../assets/images/pdfImage.png"
import DocumentPreview from '../WithBureau/WithBureauQueryForm/DocumentPreview';
import CaptureModal from '../WithBureau/WithBureauQueryForm/CaptureModal';
import LogoModalLoader from '../@common/Modal/WithBureauModal/LogoModalLoader';
import AlertModal from '../@common/Modal/AlertModal';
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import imgEng from "../../assets/images/Eng.svg";
import imgspa from "../../assets/images/Spanish.svg";
import { useNavigate } from 'react-router-dom';
import SuccessfullModal from '../@common/Modal/SuccessfullModal';
import { getCookiesList } from '../../utils/cookiesHelper';
import logoImage from "../../assets/images/logo-2.png";
import bubble1 from "../../assets/images/bubble1.png";
import bubble2 from "../../assets/images/bubble2.png";
import bubble3 from "../../assets/images/bubble3.png";
import bubble4 from "../../assets/images/bubble4.png";
import bubble5 from "../../assets/images/bubble5.png";
import bubble6 from "../../assets/images/bubble6.png";
import bubble7 from "../../assets/images/bubble7.png";
import bubble8 from "../../assets/images/bubble8.png";
import bubble9 from "../../assets/images/bubble9.png";
import bubble10 from "../../assets/images/bubble10.png";
import bubble11 from "../../assets/images/bubble11.png";
import bubble12 from "../../assets/images/bubble12.png";
import bubble13 from "../../assets/images/bubble13.png";
import bubble14 from "../../assets/images/bubble14.png";
import bubble15 from "../../assets/images/bubble15.png";

const LegalEmail = () => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const search_params = new URLSearchParams(window.location.search);
    const email = search_params.get("email");
    const phone = search_params.get("phone");
    const vlid = search_params.get("vlid");
    const geo_location = search_params.get("geo_location");
    const faceid = search_params.get("faceid");
    const authed = search_params.get("authed");
    const [showTextField, setShowTextField] = useState(true);
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
    const [path, setpath] = useState("");
    const [cookieList, setCookieList] = useState([]);

    const [mandatoryOne, setMandatoryOne] = useState(false);
    const [mandatoryTwo, setMandatoryTwo] = useState(false);

    const [formValid, setFormValid] = useState(false);

    const [infoAccount, setInfoAccount] = useState('');
    const [candidateEmail, setCandidateEmail] = useState('');
    const [sns, setSnS] = useState("");
    const [id, setId] = useState("");
    const [candidateNumber, setCandidateNumber] = useState('');
    const [infoPassword, setInfoPassword] = useState('');
    const [certificateData, setCertificateData] = useState('');
    const [revalidData, setRevalidData] = useState('')

    const [snsFlag, setSnsFlag] = useState(false);
    const [snsError, setSnsError] = useState("");
    const [idFlag, setIdFlag] = useState(false);
    const [idError, setIdError] = useState("");
    const [infoFlag, setInfoFlag] = useState(false);
    const [infoError, setInfoError] = useState("Field Required");

    const [ssnValid, setSsnValid] = useState(false);
    const [cedulaValid, setCedulaValid] = useState(false);
    const [infoAccValid, setInfoAccValid] = useState(false);
    const [candidatePassValid, setCandidatePassValid] = useState(false);
    const [certifyValid, setcertifyValid] = useState(false);
    const [revalidFolioValid, setRevalidFolioValid] = useState(false);
    const [candidateEmailValid, setCandidateEmailValid] = useState(false);
    const [candidatePhoneValid, setCandidatePhoneValid] = useState(false);


    const [isLogoModalOpen, setLogoModalOpen] = useState(false);
    const [showAlert, setShowAlert] = useState(false);

    const [fileList, setFileList] = useState([]);
    const [showWebcam, setShowWebcam] = useState(false);
    const [isDragActive, setIsDragActive] = useState(false);
    const [dropError, setDropError] = useState('');
    const [viewDoc, setViewDoc] = useState(false)
    const [selectedFile, setSelectedFile] = useState(null);
    const [showtext, setShowtext] = useState("");

    const [formErrors, setFormErrors] = useState({
        candidate_Email: '',
        mobile_number: ''
    });

    const storedLanguage = localStorage.getItem("selectedLanguage");
    const [language, setLanguage] = useState(storedLanguage);
    const lngs = {
        en: { nativeName: t("name.English"), code: "en" },
        es: { nativeName: t("name.Spanish"), code: "es" },
    };
    // Function to handle language change
    const handleChange = (event) => {
        const newLanguage = event.target.value;
        setLanguage(newLanguage);
        i18n.changeLanguage(newLanguage);
        localStorage.setItem("selectedLanguage", newLanguage);
    };

    const handleCheckboxChange = (event) => {
        setShowTextField(event.target.checked);
        setSnsFlag(false)
        setInfoFlag(false)
        setSsnValid(false);
        setCedulaValid(false);
        setInfoAccValid(false);
        setCandidatePassValid(false);
        setcertifyValid(false);
        setRevalidFolioValid(false);
        setCandidateEmailValid(false);
        setCandidatePhoneValid(false);
        setMandatoryOne(false)
        setMandatoryTwo(false)
        setCandidateEmail("")
        setCandidateNumber("")
        setInfoAccount("")
        setInfoPassword("")
    };


    const snsHandler = (e) => {
        let input = e.target.value;
        input = input.replace(/[^0-9,]/g, ''); // Allow only numbers and commas
        input = input.replace(/,{2,}/g, ','); // Replace consecutive commas with a single comma
        input = input.replace(/(^,)/g, '');
        const values = input.split(',').filter(Boolean);

        if (values.length === 0) {
            // If there is no input, set ssnValid to false and clear SNS error
            setSnsFlag(false);
            setSnsError("");
            setSsnValid(false);
        } else if (values.length > 4) {
            setSnsFlag(true);
            setSnsError(t("thanks.ssnError"));
            setSsnValid(false);
        } else {
            const reg = /^[0-9]{11}$/;
            const allValid = values.every((value) => reg.test(value));
            if (allValid) {
                setSnsFlag(false);
                setSnsError("");
                setSnS(values);
                setSsnValid(true);
            } else {
                setSnsFlag(true);
                setSnsError(t("thanks.ssnError"));
                setSsnValid(false);
            }
        }
        // Update the input value to only include valid characters
        e.target.value = input;
    };

    const infonavithandler = (e) => {
        const reg = /^[0-9]{11}$/;
        let input = e.target.value;
        const validReg = reg.test(input);

        if (input.length === 0) {
            setInfoFlag(false);
            setInfoAccValid(false);
            setInfoError(""); // Clear the error
        } else if (validReg) {
            setInfoFlag(false);
            setInfoAccValid(true);
            setInfoError(""); // Clear the error
        } else {
            setInfoFlag(true);
            setInfoAccValid(false);
            setInfoError(t("thanks.infoError"));
        }

        setInfoAccount(input); // Update the state with the input value
    };

    const emailhandler = (e) => {
        const input = e.target.value;
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email pattern

        // Check if the email matches the pattern
        if (input.length === 0) {
            setCandidateEmailValid(false);
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                candidate_Email: "",
            }));
        } else if (!emailPattern.test(input)) {
            setCandidateEmailValid(false);
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                candidate_Email: t("consultField.emailvalid"),
            }));
        } else {
            setCandidateEmailValid(true);
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                candidate_Email: "", // Clear the error if the email is valid
            }));
        }

        setCandidateEmail(input);
    };

    const cellPhoneHandler = (e) => {
        const input = e.target.value;
        const mobilePattern = /^[0-9]{10}$/; // Adjust the pattern as needed

        if (input.length === 0) {
            setCandidatePhoneValid(false); // Invalid if input is empty
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                mobile_number: "",
            }));
        } else if (mobilePattern.test(input)) {
            setCandidatePhoneValid(true); // Valid if matches the pattern
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                mobile_number: "",
            }));
        } else {
            setCandidatePhoneValid(false); // Invalid if doesn't match the pattern
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                mobile_number: t("consultField.candidatePhoneValid"),
            }));
        }
        setCandidateNumber(input); // Update the phone number state
    };

    const idHandler = (e) => {
        const reg = /^[0-9]*$/;  // Regular expression to check for numbers only
        const input = e.target.value.trim(); // Remove leading and trailing spaces
        const validReg = reg.test(input);

        if (input.length === 0) {
            // If input is empty, set cedulaValid to false and clear errors
            setIdFlag(false);
            setCedulaValid(false);
            setIdError("");
        } else if (validReg) {
            // If input is valid (only numbers), check for minimum length
            if (input.length >= 6) {
                // If input has at least 6 characters, set cedulaValid to true
                setIdFlag(false);
                setCedulaValid(true);
                setIdError("");
            } else {
                // If input has less than 6 characters, show error
                setIdFlag(true);
                setCedulaValid(false);
                setIdError(t("thanks.enternumber")); // Custom error message for minimum length
            }
        } else {
            // If input is invalid (not numbers), show error
            setIdFlag(true);
            setCedulaValid(false);
            setIdError(t("thanks.enternumber"));
        }
        setId(input); // Update the input state
    };

    const candidatePasshandler = (e) => {
        const input = e.target.value;
        if (input.length === 0) {
            setCandidatePassValid(false);
        } else {
            setCandidatePassValid(true);
        }
        setInfoPassword(input); // Update the password state
    };

    const certifyDatahandler = (e) => {
        setCertificateData(e.target.value);
        setcertifyValid(true)
    }
    const revalidDatahandler = (e) => {
        setRevalidData(e.target.value);
        setRevalidFolioValid(true)
    }

    const onDrop = useCallback(async (acceptedFiles) => {
        setDropError(''); // Clear any previous errors
        const allowedTypes = ['image/png', 'image/jpeg', 'application/pdf'];
        const newFiles = [];
        for (const file of acceptedFiles) {
            if (allowedTypes.includes(file.type)) {
                try {
                    const formData = new FormData();
                    formData.append('file', file);
                    formData.append('email', cookieList[3]);
                    formData.append('vlid', vlid);
                    handleOpenLogoModal();
                    // Upload file to the API
                    const response = await axios.post(process.env.REACT_APP_BASE_URL2 + "pending_upload_document", formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });

                    if (response.data) {
                        handleCloseLogoModal();
                        if (response?.data?.error === null) {
                            const uploadedFile = {
                                ...file,
                                preview: URL.createObjectURL(file),
                                type: file.type,
                                name: file.name,
                                response: response.data
                            };

                            newFiles.push(uploadedFile);
                        }
                        else if (response?.data?.error === "we already have a updloaded") {
                            setShowAlert(true);
                            setShowtext(t("consultField.alreadyUploadError"));
                        }
                        else {
                            setDropError(t("consultField.invalidDoc"))
                        }
                    }
                }
                catch (error) {
                    handleCloseLogoModal()
                    console.error('Error uploading file:', error);
                    setShowAlert(true);
                    setShowtext(t("GuestDashboard.consultPopUpMessageDataError"));
                }
            }
            else {
                setDropError(`${t('consultField.fileTypeError')}: ${file.name}`);
            }
        }

        if (fileList.length + newFiles.length > 10) {
            setDropError(t("consultField.maxFileError"));
        } else {
            setFileList(prevFiles => [
                ...prevFiles,
                ...newFiles
            ]);
        }
    }, [fileList, t]);


    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: 'image/png, image/jpeg, application/pdf',
        maxSize: 15728640, // 15 MB in bytes,
        // maxFiles: 1, // Allow only one file at a time
        onDragEnter: () => setIsDragActive(true),
        onDragLeave: () => setIsDragActive(false),
        onDropAccepted: () => setIsDragActive(false),
        onDropRejected: () => setIsDragActive(false),
        onDropRejected: (fileRejections) => {
            setIsDragActive(false);
            fileRejections.forEach(rejection => {
                // if (rejection.errors.some(e => e.code === 'too-many-files')) {
                //     setDropError(t('consultField.onlyOneFileError')); // Handle multiple file rejection
                // }
                if (rejection.errors.some(e => e.code === 'file-too-large')) {
                    setDropError(`${t('consultField.fileSizeError')}: ${rejection.file.name}`);
                }
            });
        },
    });

    const handlePreview = (file) => {
        setViewDoc(true)
        setSelectedFile(file);
    }

    const handleDelete = (index) => {
        setFileList((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };

    // Logo modal Handlers
    const handleOpenLogoModal = () => {
        setLogoModalOpen(true);
    };

    const handleCloseLogoModal = () => {
        setLogoModalOpen(false);
    };

    const handleCloseAlert = () => {
        setShowAlert(false);
    };

    const additionalDataHandler = async () => {
        handleOpenLogoModal();
        const endpoint = process.env.REACT_APP_BASE_URL2 + "update_pending_information";

        const params = {
            documents: fileList.map(file => ({
                ...file.response
            })),
            vlid: vlid,
            sujeto_nss: sns,
            sujeto_cedula: id,
            sujeto_infonavit_account_number: infoAccount,
            sujeto_candidate_password: infoPassword,
            sujeto_certificate_folio: certificateData,
            sujeto_revalidation_folio: revalidData,
            sujeto_candidate_email: candidateEmail,
            sujeto_candidate_phone_number: candidateNumber
        }

        try {
            const response = await axios.post(endpoint, params, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response?.data?.error == '') {
                handleCloseLogoModal();
                setIsSuccessModalOpen(true)
                // setpath("/dashboard")
            }
            else if (response?.data?.error) {
                handleCloseLogoModal();
                setShowtext(response?.data?.error);
                setShowAlert(true);
            }
        }
        catch (error) {
            handleCloseLogoModal();
            setShowAlert(true);
            setShowtext(t("GuestDashboard.consultPopUpMessageDataError"));
        }
    }

    const checkValid = () => {
        // Resetting validity states for `ssn` and `cedula` based on dependent pairs

        // Infonavit account & password pair
        if ((infoAccValid && !candidatePassValid) || (!infoAccValid && candidatePassValid)) {
            setSsnValid(false);
            setCedulaValid(false);
            setCandidateEmailValid(false);
            setCandidatePhoneValid(false);
            setcertifyValid(false);
            setRevalidFolioValid(false);
            setMandatoryOne(true)

        }

        // Candidate email & phone pair
        else if ((candidateEmailValid && !candidatePhoneValid) || (!candidateEmailValid && candidatePhoneValid)) {
            setSsnValid(false);
            setCedulaValid(false);
            setInfoAccValid(false);
            setCandidatePassValid(false);
            setcertifyValid(false);
            setRevalidFolioValid(false);
            setMandatoryTwo(true)

        }
        // If all conditions are met for pairs, set all fields to valid
        else {
            if (sns) {
                setSsnValid(true);
            }
            if (id) {
                setCedulaValid(true);
            }
            if (infoAccount) {
                setInfoAccValid(true);
            }
            if (infoPassword) {
                setCandidatePassValid(true);
            }
            if (candidateEmail) {
                setCandidateEmailValid(true);
            }
            if (candidateNumber) {
                setCandidatePhoneValid(true);
            }
            if (certificateData) {
                setcertifyValid(true);
            }
            if (revalidData) {
                setRevalidFolioValid(true);
            }
            if (!infoAccount && !infoPassword) {
                setMandatoryOne(false)
            }
            if (!candidateEmail && !candidateNumber) {
                setMandatoryTwo(false)
            }
        }
    };


    useEffect(() => {
        // Form is valid if:
        // - Only NSS is valid and no Cedula is provided
        // - Both NSS and Cedula are valid
        // - Only Cedula is valid and no NSS is provided
        const isNssOnlyValid = ssnValid && !cedulaValid && !idFlag && !snsError;
        const isBothValid = ssnValid && cedulaValid && !snsError && !idError;
        const isCedulaOnlyValid = cedulaValid && !ssnValid && !snsFlag && !idError;

        // Form is invalid if there are errors
        if (snsError || idError) {
            setFormValid(false);
            return;
        }

        // Set form validity
        if (isNssOnlyValid || isBothValid || isCedulaOnlyValid) {
            setFormValid(true);
        } else {
            setFormValid(false);
        }
    }, [ssnValid, cedulaValid, snsError, idError, snsFlag, idFlag]);


    useEffect(() => {
        checkValid();
    }, [infoAccValid, candidatePassValid, candidateEmailValid, candidatePhoneValid]);


    useEffect(() => {
        const userCookiesList = getCookiesList("userInfo");
        if (userCookiesList.length > 0) {
            setCookieList(userCookiesList);
        }
    }, []);

    const nextStep = () => {
        console.log(['nextStep', faceid, geo_location]);

        if (faceid) {
            navigate("/KYC" + window.location.search);
        } else if (geo_location) {
            navigate("/GeoLocation" + window.location.search);
        } else {
            window.open("https://searchlook.com.mx", "_self");
        }
    }

    return (
        <>
            <section className="bubble-holder">
                <div className="bubble-1 bubble-container bubble-animation-x">
                    <div className="bubble-large bubble-animation-y"> <img src={bubble1} className='fullImg' /></div>
                </div>

                <div className="bubble-2 bubble-container bubble-animation-x">
                    <div className="bubble-large bubble-animation-y"> <img src={bubble2} className='fullImg' alt='bubble image' /></div>
                </div>

                <div className="bubble-3 bubble-container bubble-animation-x">
                    <div className="bubble-large bubble-animation-y"> <img src={bubble3} className='fullImg' alt='bubble image' /></div>
                </div>

                <div className="bubble-4 bubble-container bubble-animation-x">
                    <div className="bubble-small bubble-animation-y"> <img src={bubble4} className='fullImg' alt='bubble image' /></div>
                </div>

                <div className="bubble-5 bubble-container bubble-animation-x">
                    <div className="bubble-small bubble-animation-y"> <img src={bubble5} className='fullImg' alt='bubble image' /></div>
                </div>

                <div className="bubble-6 bubble-container bubble-animation-x">
                    <div className="bubble-small bubble-animation-y"> <img src={bubble6} className='fullImg' alt='bubble image' /></div>
                </div>

                <div className="bubble-7 bubble-container bubble-animation-x">
                    <div className="bubble-small bubble-animation-y"> <img src={bubble7} className='fullImg' alt='bubble image' /></div>
                </div>

                <div className="bubble-8 bubble-container bubble-animation-x">
                    <div className="bubble-small bubble-animation-y"> <img src={bubble8} className='fullImg' alt='bubble image' /></div>
                </div>
                <div className="bubble-1 bubble-container bubble-animation-x">
                    <div className="bubble-large bubble-animation-y"> <img src={bubble9} className='fullImg' alt='bubble image' /></div>
                </div>

                <div className="bubble-2 bubble-container bubble-animation-x">
                    <div className="bubble-large bubble-animation-y"> <img src={bubble10} className='fullImg' alt='bubble image' /></div>
                </div>

                <div className="bubble-3 bubble-container bubble-animation-x">
                    <div className="bubble-large bubble-animation-y"> <img src={bubble11} className='fullImg' alt='bubble image' /></div>
                </div>

                <div className="bubble-4 bubble-container bubble-animation-x">
                    <div className="bubble-small bubble-animation-y"> <img src={bubble12} className='fullImg' alt='bubble image' /></div>
                </div>

                <div className="bubble-5 bubble-container bubble-animation-x">
                    <div className="bubble-small bubble-animation-y"> <img src={bubble13} className='fullImg' alt='bubble image' /></div>
                </div>

                <div className="bubble-6 bubble-container bubble-animation-x">
                    <div className="bubble-small bubble-animation-y"> <img src={bubble14} className='fullImg' alt='bubble image' /></div>
                </div>

                <div className="bubble-7 bubble-container bubble-animation-x">
                    <div className="bubble-small bubble-animation-y"> <img src={bubble15} className='fullImg' alt='bubble image' /></div>
                </div>
            </section>

            <SuccessfullModal
                isOpen={isSuccessModalOpen}
                setIsOpen={setIsSuccessModalOpen}
                path={path}
                onSuccessClose={nextStep}
            />

            {showAlert && <AlertModal text={showtext} onClose={handleCloseAlert} />}

            <LogoModalLoader isOpenLogoModal={isLogoModalOpen} />

            <DocumentPreview
                viewDoc={viewDoc}
                setViewDoc={setViewDoc}
                file={selectedFile}
            />

            <CaptureModal
                showWebcam={showWebcam}
                setShowWebcam={setShowWebcam}
                setFileList={setFileList}
                fileList={fileList}
                setDropError={setDropError}
            />

            <Grid
                container
                spacing={2}
                sx={{
                    backgroundColor: " #FAFCFF",
                }}>
                <Grid item xs={12} sm={12}>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "end",
                            p: 4
                        }}>
                        <FormControl
                            sx={{
                                mt: 0,
                                maxWidth: "200px",
                                minWidth: 80,
                                display: { xs: "none", lg: "block" },
                            }}
                            size="small"
                        >
                            <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                value={language}
                                displayEmpty
                                onChange={handleChange}
                                sx={{
                                    padding: "7px 10px",
                                    display: "flex",
                                    alignItems: "center",
                                    "& .MuiSelect-select": {
                                        display: "flex !important",
                                        alignItems: "center !important",
                                    },
                                    "& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputSizeSmall.css-17ayv1u-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                                    {
                                        display: "flex",
                                        alignItems: "center",
                                    },
                                }}
                            >
                                {Object.keys(lngs).map((lng) => (

                                    <MenuItem key={lngs[lng].code} value={lngs[lng].code}>
                                        <Tooltip title={t("changeLanguage.Flag")} arrow placement="top">
                                            <img
                                                src={lng === "en" ? imgEng : imgspa}
                                                alt={lngs[lng].nativeName}
                                                width="30"
                                                height="25"
                                                style={{ marginRight: "10px" }}
                                            />
                                        </Tooltip>
                                        {lng === "en" ? lngs[lng].nativeName : "Español"}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl
                            sx={{
                                mt: 0,
                                minWidth: 50,
                                display: { xs: "block", lg: "none" },
                            }}
                            size="small"
                        >
                            <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                value={language}
                                displayEmpty
                                onChange={handleChange}
                                sx={{
                                    padding: "4px 0px !important",
                                    display: "flex",
                                    alignItems: "center",
                                    "& .MuiSelect-select": {
                                        display: "flex !important",
                                        alignItems: "center !important",
                                    },
                                    "& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputSizeSmall.css-17ayv1u-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                                    {
                                        display: "flex",
                                        alignItems: "center",
                                    },
                                }}
                            >
                                {Object.keys(lngs).map((lng) => (
                                    <MenuItem
                                        key={lngs[lng].code}
                                        value={lngs[lng].code}
                                        sx={{ justifyContent: "center" }}
                                    >
                                        <Tooltip title={t("changeLanguage.Flag")} arrow placement="top">
                                            <img
                                                src={lng === "en" ? imgEng : imgspa}
                                                alt={lngs[lng].nativeName}
                                                width="20"
                                                height="20"
                                            />
                                        </Tooltip>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>
                <Box sx={{
                    height: "calc(100vh - 123px)",
                    overflowY: "scroll"
                }}>
                    <Grid item xs={12} sm={12}>
                        <Box>
                            <Typography
                                sx={{
                                    fontFamily: "Poppins-SemiBold",
                                    fontSize: "24px",
                                    lineHeight: "35px",
                                    textAlign: "center",
                                    color: "#1C212D",
                                }}
                            >
                                {t("consultField.AdditionalInformationDocuments")}
                            </Typography>
                            <Box sx={{ textAlign: "center", mt: -1 }}>
                                <img src={underLine} alt="under line" />
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={12}
                    >
                        <Box sx={{
                            backgroundColor: "#ffffff",
                            // p: 4,
                            width: "100%",
                            maxWidth: "90%",
                            margin: "12px auto",

                            filter: isDragActive ? "blur(1px)" : "",
                            border: isDragActive ? "2px solid #D9E0FD" : "",
                            borderRadius: isDragActive ? "12px" : "",
                            position: "relative",
                            zIndex: "1"

                        }}
                            {...getRootProps()}
                        >
                            <Grid item xs={12} sm={showTextField ? 12 : 6}
                                onClick={(event) => {
                                    event.stopPropagation();
                                }}
                                sx={{
                                    display: !showTextField ? "flex" : "",
                                    alignItems: "center",
                                    pl: 4,
                                    pr: 4,
                                    pt: 4
                                }}
                            >
                                <Box sx={{ mt: { xs: 0, sm: 1 } }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={showTextField}
                                                onChange={handleCheckboxChange}
                                            />
                                        }
                                        label={t("labels.CandidateDocumentation(Optional)")}
                                    />
                                </Box>
                                {showTextField && (
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6} mt={2}>
                                            <Box sx={{ mt: { xs: 0, sm: 0 } }}>
                                                <LabelInput
                                                    type="text"
                                                    subLabel=""
                                                    label=""
                                                    name="SNS"
                                                    id="SNS"
                                                    placeholder={t("placeholders.sns")}
                                                    onChange={snsHandler}
                                                />
                                            </Box>
                                            {snsFlag && (
                                                <FormHelperText sx={{ color: "red", fontSize: 12 }}>
                                                    {snsError}
                                                </FormHelperText>
                                            )}
                                        </Grid>
                                        <Grid item xs={12} sm={6} mt={2}>
                                            <Box sx={{ mt: 0 }}>
                                                <LabelInput
                                                    type="number"
                                                    subLabel=""
                                                    label=""
                                                    name="ID"
                                                    id="ID"
                                                    placeholder={t("placeholders.id")}
                                                    maxLength={7}
                                                    onChange={idHandler}
                                                />
                                            </Box>
                                            {idFlag && (
                                                <FormHelperText sx={{ color: "red", fontSize: 12 }}>
                                                    {idError}
                                                </FormHelperText>
                                            )}
                                        </Grid>
                                        <Grid item xs={12} sm={6} mt={2}>
                                            <LabelInput
                                                type="number"
                                                subLabel=""
                                                label=""
                                                name=""
                                                id=""
                                                placeholder={t("placeholders.enterInfonavit")}
                                                minLength={11}
                                                maxLength={11}
                                                onChange={infonavithandler}
                                            // Mandatory={mandatoryOne}
                                            />
                                            {infoFlag && (
                                                <FormHelperText sx={{ color: "red", fontSize: 12 }}>
                                                    {infoError}
                                                </FormHelperText>
                                            )}
                                        </Grid>
                                        <Grid item xs={12} sm={6} mt={2}>
                                            <PasswordShowHideIcon
                                                type="text"
                                                label=""
                                                name="Password"
                                                id="Password"
                                                placeholder={t("placeholders.EnterPassword")}
                                                onChange={candidatePasshandler}
                                            // mandatory = {mandatoryOne}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} mt={2}>
                                            <LabelInput
                                                type="text"
                                                subLabel=""
                                                label=""
                                                name="ID"
                                                id="ID"
                                                placeholder={t("placeholders.EnterCertificateFOLIO")}
                                                onChange={certifyDatahandler}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} mt={2}>
                                            <LabelInput
                                                type="text  "
                                                subLabel=""
                                                label=""
                                                name="ID"
                                                id="ID"
                                                placeholder={t("placeholders.EnterRevalidationFOLIO")}
                                                onChange={revalidDatahandler}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} mt={2}>
                                            <LabelInputLeftIcon
                                                mailIcon={mailImage}
                                                type="email"
                                                name="emailAddress"
                                                id="emailAddress"
                                                placeholder={t("placeholders.EnterCandidateEmail")}
                                                onChange={emailhandler}
                                            // error={formErrors.candidate_Email}
                                            // mandatory = {mandatoryTwo}
                                            />
                                            {formErrors.candidate_Email && (
                                                <Typography variant="body2" color="error">
                                                    {formErrors.candidate_Email}
                                                </Typography>
                                            )}
                                        </Grid>
                                        <Grid item xs={12} sm={6} mt={2}>
                                            <LabelInputLeftIcon
                                                mailIcon={phoneIcon}
                                                type="number"
                                                label=""
                                                name="mnumber"
                                                id="mnumber"
                                                placeholder={t("placeholders.EnterCandidatePhoneNumber")}
                                                onChange={cellPhoneHandler}
                                                // error={formErrors.mobile_number}
                                                maxLength={10}
                                            // mandatory = {mandatoryTwo}
                                            />
                                            {formErrors.mobile_number && (
                                                <Typography variant="body2" color="error">
                                                    {formErrors.mobile_number}
                                                </Typography>
                                            )}
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>

                            <Grid item xs={12} mt={4}
                                sx={{
                                    pl: 4,
                                    pr: 4,
                                    pb: 4
                                }}>
                                <Typography
                                    sx={{
                                        fontSize: 16,
                                        fontFamily: "Gilroy-Medium",
                                        color: "rgba(33, 33, 33, 0.6) !important",
                                        marginBottom: "10px "
                                    }}>
                                    {t("consultField.uploadDocuments")}
                                </Typography>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "start",
                                        gap: { sm: "24px", xs: "18px" },
                                        flexWrap: "wrap"
                                    }}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                    }}>
                                    {fileList.map((file, index) => (
                                        <Box
                                            sx={{
                                                position: "relative",
                                                boxShadow: "4px 6px 6px #FF8D001A",
                                                border: "1px solid #FFF8F0",
                                                borderRadius: "12px",
                                                display: "flex",
                                                alignItems: "center",
                                                flexDirection: "column",
                                                gap: "10px",
                                                padding: "6px",
                                                marginTop: "10px",
                                                marginBottom: "5px",
                                                width: { sm: "85px", xs: "68px" }
                                            }}
                                        >
                                            {file.type === 'application/pdf' ? (
                                                <Box
                                                    component="img"
                                                    src={pdfImage} // Replace with your PDF icon
                                                    alt={`preview-${index}`}
                                                    sx={{
                                                        width: "100%",
                                                        height: { sm: "65px", xs: "65px" },
                                                        // borderRadius: "12px",
                                                    }}
                                                />
                                            ) : (
                                                <Box
                                                    component="img"
                                                    src={file.preview}
                                                    alt={`preview-${index}`}
                                                    sx={{
                                                        width: "100%",
                                                        height: { sm: "6  5px", xs: "65px" },
                                                        borderRadius: "12px",
                                                    }}
                                                />
                                            )}
                                            <Typography
                                                variant="caption"
                                                sx={{
                                                    wordBreak: "break-all",
                                                    color: "rgba(33, 33, 33, 0.4)",
                                                    fontSize: { sm: 12, xs: 10 },
                                                    fontFamily: "Gilroy-Medium",
                                                    textAlign: "center",
                                                    lineHeight: "normal"
                                                }}
                                            >
                                                {file.name}
                                            </Typography>
                                            <Box
                                                sx={{
                                                    position: "absolute",
                                                    top: "0",
                                                    right: "-5px"
                                                }}
                                            >
                                                <Box
                                                    component="img"
                                                    src={deleteFile} // Replace with your PDF icon
                                                    alt={`preview-${index}`}
                                                    sx={{
                                                        width: { sm: "18px", xs: "16px" },
                                                        height: { sm: "18px", xs: "16px" },
                                                        display: "block",
                                                        cursor: "pointer"
                                                    }}
                                                    onClick={() => handleDelete(index)}
                                                />
                                                <Box
                                                    component="img"
                                                    src={previewFile} // Replace with your PDF icon
                                                    alt="preview"
                                                    sx={{
                                                        width: { sm: "18px", xs: "16px" },
                                                        height: { sm: "18px", xs: "16px" },
                                                        display: "block",
                                                        marginTop: "8px",
                                                        cursor: "pointer"
                                                    }}
                                                    onClick={() => handlePreview(file)}
                                                />
                                            </Box>
                                        </Box>

                                    ))}
                                </Box>
                                <Box
                                    sx={{
                                        mt: { xs: 2, sm: 2 },
                                        borderRadius: '12px',
                                        padding: { xs: "30px 20px", sm: "40px", lg: "50px" },
                                        textAlign: 'center',
                                        backgroundColor: isDragActive ? 'rgba(65, 101, 246, 0.06)' : '#FFF8F0', // Change background color when dragging
                                        transition: 'background-color 0.3s ease', // Smooth transition
                                        cursor: "pointer",
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: "column"
                                    }}
                                // {...getRootProps()}
                                >
                                    <input {...getInputProps()} />
                                    <Box
                                        component="img"
                                        src={consultUpload}
                                        alt="Upload"
                                        sx={{ width: 48, height: 48 }}
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: { sm: 18, xs: 17 },
                                            fontFamily: "Gilroy-Medium",
                                            color: "rgba(28, 33, 45, 0.7)",
                                            marginTop: "5px"
                                        }}
                                    >
                                        {t("consultField.dragFile")}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: { sm: 16, xs: 14 },
                                            fontFamily: "Gilroy-Medium",
                                            color: "rgba(33, 33, 33, 0.4)"
                                        }}
                                    >
                                        {t("consultField.supportFile")}
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            backgroundColor: "primary.main",
                                            textAlign: "center",
                                            borderRadius: "6px",
                                            py: { sm: 1.2, xs: 1 },
                                            px: { sm: 5, xs: 3 },
                                            color: "#fff",
                                            textDecoration: "none",
                                            fontSize: { sm: 18, xs: 16 },
                                            fontFamily: "Gilroy-Medium",
                                            textTransform: "capitalize",
                                            margin: "10px",
                                        }}
                                    >
                                        {t("consultField.chooseFile")}
                                    </Button>
                                    <Typography
                                        sx={{
                                            fontSize: 16,
                                            fontFamily: "Gilroy-Medium",
                                            color: "rgba(33, 33, 33, 0.7)",
                                            display: { md: "block", lg: "none" },
                                        }}
                                    >
                                        Or
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        startIcon={<CameraAltIcon />}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            setShowWebcam(true)
                                        }}
                                        sx={{
                                            display: { md: "flex", lg: "none" },
                                            margin: "5px 0 15px 0",
                                            textAlign: "center",
                                            borderRadius: "6px",
                                            py: { sm: 1.2, xs: 1 },
                                            px: { sm: 5, xs: 3 },
                                            color: "#fff",
                                            textDecoration: "none",
                                            fontSize: { sm: 18, xs: 16 },
                                            fontFamily: "Gilroy-Medium",
                                            textTransform: "capitalize",
                                            "& svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root": {
                                                fontSize: "24px !important"
                                            }
                                        }}
                                    >
                                        {t("consultField.capture")}

                                    </Button>
                                    <Typography
                                        sx={{
                                            fontSize: 14,
                                            fontFamily: "Gilroy-Medium",
                                            color: "rgba(33, 33, 33, 0.4)"
                                        }}
                                    >
                                        {t("consultField.maxFile")}
                                    </Typography>
                                </Box>
                                {dropError && (
                                    <Alert severity="error" sx={{ mt: 2 }}>
                                        {dropError}
                                    </Alert>
                                )}
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "start",
                                        marginTop: 2,
                                        gap: "12px",
                                        flexWrap: { xs: 'wrap', sm: 'wrap', lg: 'nowrap' },
                                        flexDirection: { xs: 'column', sm: 'column', lg: 'row' }
                                    }}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 14,
                                            fontFamily: "Gilroy-Medium",
                                            color: "rgba(33, 33, 33, 0.7)",
                                            width: "350px "
                                        }}
                                    >
                                        {t("consultField.listOfDocuments")}
                                    </Typography><Typography
                                        sx={{
                                            fontSize: 14,
                                            fontFamily: "Gilroy-Medium",
                                            color: "rgba(33, 33, 33, 0.4)"
                                        }}
                                    >
                                        {t("consultField.documentList")}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} sx={{ p: 4 }}>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                mt: 4,
                                width: "100%"
                            }}
                            onClick={(event) => {
                                event.stopPropagation();
                            }}
                        >
                            <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-start' }}>

                            </Box>
                            <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: "primary.main",
                                        textAlign: "center",
                                        borderRadius: "6px",
                                        py: 1.5,
                                        px: 8,
                                        color: "#fff",
                                        textDecoration: "none",
                                        fontSize: 18,
                                        fontFamily: "Gilroy-Medium",
                                        textTransform: "capitalize",
                                    }}
                                    disabled={fileList.length > 0 ? false : !(showTextField ? ((formValid) || (infoAccValid && candidatePassValid) || (certifyValid || revalidFolioValid) || (candidateEmailValid && candidatePhoneValid)) : false)}
                                    onClick={additionalDataHandler}
                                >
                                    {t("consultField.submit")}
                                </Button>
                            </Box>
                            <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: "secondary.main",
                                        textAlign: "center",
                                        margin: "auto",
                                        borderRadius: "6px",
                                        py: 1.5,
                                        px: 8,
                                        color: "#fff",
                                        textDecoration: "none",
                                        fontSize: 18,
                                        fontFamily: "Gilroy-Medium",
                                        textTransform: "capitalize",
                                    }}
                                    onClick={nextStep}
                                >
                                    {t("consultField.skip")}
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Box>
            </Grid >
        </>
    )
}

export default LegalEmail