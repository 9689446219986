import { Box, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import privacyImg from "../../../assets/images/resend-privacy.svg";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import reportimg from "../../../assets/images/reporticonblue.svg";
import blueCircle from "../../../assets/images/reportBlue.png"


const SendEmailConmponent = ({
  clickLocation,
  handleEmailClose,
  sendLegalEmail,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Modal
        open={true}
        onClose={handleEmailClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>
          <div
            style={{
              position: "absolute",
              top: clickLocation.y,
              left: clickLocation.x,
              transform: "translate(-50%, -50%)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                display: "block",
                backgroundColor: "white", // White with 50% transparency
                fontSize: 14,
                fontFamily: "Gilroy-Medium",
                width: "45px",
                height: "45px",
                borderRadius: "50%",
              }}
            >
              {" "}
              <img
                src={blueCircle}
                width={"24px"}
                alt="icon email send"
                style={{
                  paddingTop: "10px",
                  marginLeft: "10px",
                  display: "block",
                }}
              />
            </Typography>
          </div>
          <div
            style={{
              position: "absolute",
              top: clickLocation.y,
              left: clickLocation.x,
              transform: "translate(-50%, -50%)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "-85px",

            }}
          >
            <Typography
              sx={{
                display: "block",
                backgroundColor: "rgb(65, 101, 246)", // White with 50% transparency
                fontSize: 14,
                fontFamily: "Gilroy-Medium",
                width: "45px",
                height: "45px",
                borderRadius: "50%",
                cursor: "pointer",
              }}
              onClick={sendLegalEmail}
            >
              {" "}
              <img
                src={privacyImg}
                width={"24px"}
                alt="icon email send"
                style={{
                  display: "flex",
                  margin: "16px auto",
                }}
              />
            </Typography>
          </div>
          <Typography
            sx={{
              position: "absolute",
              top: clickLocation.y,
              left: clickLocation.x,
              transform: "translate(-50%, -50%)",
              marginTop: "-50px",
              color: "#ffffff",
              fontSize: 14,
              fontFamily: "Gilroy-Medium",
            }}
          >
            {t("buttons.sendEmail")}
          </Typography>
        </div>
      </Modal>
    </>
  );
};

export default SendEmailConmponent;
