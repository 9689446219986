import axios from 'axios';
import React, { useState, useEffect, useCallback } from 'react';
import { useJsApiLoader, GoogleMap, Marker } from '@react-google-maps/api';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, Typography, CircularProgress } from '@mui/material';
import { TextField, List, ListItem, ListItemText } from '@mui/material';
import debounce from 'lodash/debounce';
import './MapModal.css';
import TrackingPopup from './TrackingPopup';

const MapModal = ({ vlid }) => {
  const mexico_city = { lat: 19.4326, lng: -99.1332 };

  const [show, setShow] = useState(true);
  const [geoLocation, setGeoLocation] = useState(mexico_city);
  const [marker, setMarker] = useState(mexico_city);
  const [address, setAddress] = useState('');
  const [predictions, setPredictions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showTracking, setShowTracking] = useState(false);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const mapStyles = {
    height: "33vh",
  };

  useEffect(() => {
    try {
      navigator.geolocation.getCurrentPosition((position) => {
        let loc = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        setGeoLocation(loc);
        setMarker(loc);
      });
    } catch (error) {
      console.log('Error getting location', error);
    }
  }, []);

  const handleMapClick = (event) => {
    setMarker({
      lat: event.latLng.lat(),
      lng: event.latLng.lng()
    });
    console.log('map clicked', marker);
  };

  const handleMarkerDrag = (event) => {
    setMarker({
      lat: event.latLng.lat(),
      lng: event.latLng.lng()
    });
    console.log('marker dragged', marker);
  };

  const saveLocation = (newLocation, type) => {
    axios.post(process.env.REACT_APP_BASE_URL2 + 'save_geoloc', {
      vlid: vlid,
      geoloc: newLocation,
      type: type
    }).catch(error => {
      console.error('Error sending location:', error);
    });
  };

  const startLocationTracking = useCallback(() => {
    const trackLocation = () => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const newLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          console.log('Sending tracker location:', newLocation);
          saveLocation(newLocation, 'tracker');
        },
        (error) => {
          console.error('Error getting location:', error);
        }
      );
    };

    const intervalId = setInterval(trackLocation, 3600*1000);

    return () => clearInterval(intervalId);
  }, []);

  const handleConfirm = () => {
    console.log('Sending manual location:', marker);
    
    saveLocation(marker, 'manual');
    setShow(false);
    setShowTracking(true);
    startLocationTracking();
  };

  const debouncedSearch = useCallback(
    debounce(async (input) => {
      if (input.length > 2) {
        try {
          setIsLoading(true);
          const response = await axios.post(process.env.REACT_APP_BASE_URL2 + 'address_autocomplete', { input });
          setPredictions(response.data.predictions);
        } catch (error) {
          console.error('Error fetching predictions:', error);
        } finally {
          setIsLoading(false);
        }
      } else {
        setPredictions([]);
      }
    }, 2000),
    []
  );

  const handleAddressChange = (event) => {
    const input = event.target.value;
    setAddress(input);
    debouncedSearch(input);
  };

  const handleAddressSelect = async (prediction) => {
    try {
      setIsLoading(true);
      setAddress(prediction.description);
      setPredictions([]);
      const response = await axios.post(process.env.REACT_APP_BASE_URL2 + 'get_geoloc', { place_id: prediction.place_id });
      const { lat, lng } = response.data.result.geometry.location;
      setMarker({ lat, lng });
      setGeoLocation({ lat, lng });
    } catch (error) {
      console.error('Error fetching location:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  return (
    <>
      <Dialog open={show}>
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Company asked to get your home address. Verify Your Home Location.</Typography>
          </Box>
        </DialogTitle>
        <DialogContent className="mapContent">
          <TextField
            label="Address"
            value={address}
            onChange={handleAddressChange}
            fullWidth
            className="addressInput"
            InputProps={{
              endAdornment: isLoading && <CircularProgress size={20} />
            }}
          />
          {predictions.length > 0 && (
            <List className="predictionList">
              {predictions.slice(0, 3).map((prediction) => (
                <ListItem button key={prediction.place_id} onClick={() => handleAddressSelect(prediction)}>
                  <ListItemText primary={prediction.description} />
                </ListItem>
              ))}
            </List>
          )}
          {isLoaded && (
            <div style={{ position: 'relative' }}>
              <GoogleMap
                mapContainerStyle={mapStyles}
                zoom={10}
                center={geoLocation}
                onClick={handleMapClick}
              >
                <Marker
                  position={marker}
                  draggable={true}
                  onDrag={handleMarkerDrag}
                />
              </GoogleMap>
              {isLoading && (
                <div style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'rgba(255, 255, 255, 0.7)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                  <CircularProgress />
                </div>
              )}
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirm} color="primary" variant="contained">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <TrackingPopup open={showTracking} />
    </>
  );
};

export default MapModal;