import React, { useCallback, useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Alert, Box, Grid, Modal, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import underLine from "../../../../assets/images/under-line.png";
import DocumentPreview from "../../../WithBureau/WithBureauQueryForm/DocumentPreview";
import CaptureModal from "../../../WithBureau/WithBureauQueryForm/CaptureModal";
import deleteFile from "../../../../../src/assets/images/deletefile.svg";
import previewFile from "../../../../../src/assets/images/previewFile.svg";
import pdfImage from "../../../../assets/images/pdfImage.png";
import consultUpload from "../../../../../src/assets/images/consultUpload.svg";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { useDropzone } from "react-dropzone";
import Button from "@mui/material/Button";
import axios from "axios";
import AlertModal from "../AlertModal";
import LogoModalLoader from "./LogoModalLoader";
import { getCookiesList } from "../../../../utils/cookiesHelper";

const DocUploadModal = (props) => {
  const { t } = useTranslation();
  const { docOpen, setDocOpen, onSaveDocuments, documents, rowNbc } = props;
  const [showWebcam, setShowWebcam] = useState(false);
  const [isDragActive, setIsDragActive] = useState(false);
  const [dropError, setDropError] = useState("");
  const [viewDoc, setViewDoc] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileList, setFileList] = useState(documents || []);
  const [isLogoModalOpen, setLogoModalOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false); // Add a state to control the visibility of the alert
  const [showtext, setShowtext] = useState("");
  const [cookieList, setCookieList] = useState([]);
  const [nbcId, setNbcId] = useState(rowNbc || "");

  useEffect(() => {
    setFileList(documents || []);
    setNbcId(rowNbc || ""); // Update nbc_Id when row changes
  }, [documents, rowNbc]);

  // Logo modal Handlers
  const handleOpenLogoModal = () => {
    setLogoModalOpen(true);
  };

  const handleCloseLogoModal = () => {
    setLogoModalOpen(false);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const style = {
    "*::-webkit-scrollbar": {
      width: "6px !important",
      borderRadius: "3px !important",
      height: "6px !important",
    },
    "*::-webkit-scrollbar-track": {
      backgroundColor: "#E4EFEF !important",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "#4165f1d4 !important",
      borderRadius: "3px !important",
    },
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75%",
    maxWidth: { sm: "75%", xs: "95%" },
    maxHeight: "90vh", // To ensure modal content is scrollable
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "20px",
    overflowY: "auto", // Scrollable content if it exceeds modal height
  };

  const handleClose = () => {
    setDocOpen(false);
    setFileList(documents || []);
    setNbcId("" || rowNbc);
  };

  const onDrop = useCallback(
    async (acceptedFiles) => {
      setDropError(""); // Clear any previous errors
      const allowedTypes = ["image/png", "image/jpeg", "application/pdf"];
      const newFiles = [];
      for (const file of acceptedFiles) {
        if (allowedTypes.includes(file.type)) {
          try {
            const formData = new FormData();
            formData.append("file", file);
            formData.append("email", cookieList[3]);
            formData.append("nbc_id", nbcId);
            handleOpenLogoModal();
            // Upload file to the API
            const response = await axios.post(
              process.env.REACT_APP_BASE_URL2 + "upload_document",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );

            if (response.data) {
              handleCloseLogoModal();

              // Check if the document already exists based on document_name
              const documentName = response?.data?.document_name;
              const isDuplicate = fileList.some(
                (existingFile) =>
                  existingFile.response.document_name === documentName
              );

              if (isDuplicate) {
                setShowAlert(true);
                setShowtext(t("consultField.alreadyUploadError"));
                return;
              }
              //
              if (response?.data?.error === null) {
                const uploadedFile = {
                  ...file,
                  preview: URL.createObjectURL(file),
                  type: file.type,
                  name: file.name,
                  response: response.data,
                };

                newFiles.push(uploadedFile);
                setNbcId(response?.data?.nbc_id);
              } else if (
                response?.data?.error === "we already have a updloaded"
              ) {
                setShowAlert(true);
                setShowtext(t("consultField.alreadyUploadError"));
              } else {
                setDropError(t("consultField.invalidDoc"));
              }
            }
          } catch (error) {
            handleCloseLogoModal();
            console.error("Error uploading file:", error);
            setShowAlert(true);
            setShowtext(t("GuestDashboard.consultPopUpMessageDataError"));
          }
        } else {
          setDropError(`${t("consultField.fileTypeError")}: ${file.name}`);
        }
      }

      if (fileList.length + newFiles.length > 10) {
        setDropError(t("consultField.maxFileError"));
      } else {
        setFileList((prevFiles) => [...prevFiles, ...newFiles]);
      }
    },
    [fileList, t, nbcId]
  );

  const handleDelete = (index) => {
    setFileList((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };
  const handlePreview = (file) => {
    setViewDoc(true);
    setSelectedFile(file);
  };
  const handleSubmit = () => {
    onSaveDocuments(fileList, nbcId);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/png, image/jpeg, application/pdf",
    maxSize: 15728640, // 15 MB in bytes
    maxFiles: 1, // Allow only one file at a time
    onDragEnter: () => setIsDragActive(true),
    onDragLeave: () => setIsDragActive(false),
    onDropAccepted: () => setIsDragActive(false),
    onDropRejected: () => setIsDragActive(false),
    onDropRejected: (fileRejections) => {
      setIsDragActive(false);
      fileRejections.forEach((rejection) => {
        if (rejection.errors.some((e) => e.code === "too-many-files")) {
          setDropError(t("consultField.onlyOneFileError")); // Handle multiple file rejection
        }
        if (rejection.errors.some((e) => e.code === "file-too-large")) {
          setDropError(
            `${t("consultField.fileSizeError")}: ${rejection.file.name}`
          );
        }
      });
    },
  });

  useEffect(() => {
    const userCookiesList = getCookiesList("userInfo");
    if (userCookiesList.length > 0) {
      setCookieList(userCookiesList);
    }
  }, []);

  return (
    <>
      <DocumentPreview
        viewDoc={viewDoc}
        setViewDoc={setViewDoc}
        file={selectedFile}
      />
      <CaptureModal
        showWebcam={showWebcam}
        setShowWebcam={setShowWebcam}
        setFileList={setFileList}
        fileList={fileList}
        setDropError={setDropError}
        nbcId={nbcId}
      />
      <LogoModalLoader isOpenLogoModal={isLogoModalOpen} />

      {showAlert && <AlertModal text={showtext} onClose={handleCloseAlert} />}

      <Modal
        open={docOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              position: "fixed",
              top: "16px",
              right: "16px",
            }}
          >
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Grid item xs={12} sm={12} sx={{ marginBottom: "20px" }}>
            <Box sx={{ mt: { xs: 2, sm: 1 } }}>
              <Typography
                sx={{
                  fontFamily: "Poppins-SemiBold",
                  fontSize: { xs: "24px", sm: "26px" },
                  lineHeight: "35px",
                  textAlign: "center",
                  color: "#1C212D",
                }}
              >
                {t("consultField.uploadDocuments")}
              </Typography>
              <Box sx={{ textAlign: "center", mt: -1 }}>
                <img src={underLine} alt="under line" />
              </Box>
            </Box>
          </Grid>
          <Box
            sx={{
              height: "100%",
              maxHeight: "50vh",
              overflowY: "auto",
              paddingRight: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "start",
                gap: { sm: "24px", xs: "18px" },
                flexWrap: "wrap",
              }}
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              {fileList.map((file, index) => (
                <Box
                  sx={{
                    position: "relative",
                    boxShadow: "4px 6px 6px #FF8D001A",
                    border: "1px solid #FFF8F0",
                    borderRadius: "12px",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: "10px",
                    padding: "6px",
                    marginTop: "10px",
                    marginBottom: "5px",
                    width: { sm: "85px", xs: "68px" },
                  }}
                >
                  {file.type === "application/pdf" ? (
                    <Box
                      component="img"
                      src={pdfImage} // Replace with your PDF icon
                      alt={`preview-${index}`}
                      sx={{
                        width: "100%",
                        height: { sm: "65px", xs: "65px" },
                        // borderRadius: "12px",
                      }}
                    />
                  ) : (
                    <Box
                      component="img"
                      src={file.preview}
                      alt={`preview-${index}`}
                      sx={{
                        width: "100%",
                        height: { sm: "6  5px", xs: "65px" },
                        borderRadius: "12px",
                      }}
                    />
                  )}
                  <Typography
                    variant="caption"
                    sx={{
                      wordBreak: "break-all",
                      color: "rgba(33, 33, 33, 0.4)",
                      fontSize: { sm: 12, xs: 10 },
                      fontFamily: "Gilroy-Medium",
                      textAlign: "center",
                      lineHeight: "normal",
                    }}
                  >
                    {file.name}
                  </Typography>
                  <Box
                    sx={{
                      position: "absolute",
                      top: "0",
                      right: "-5px",
                    }}
                  >
                    <Box
                      component="img"
                      src={deleteFile} // Replace with your PDF icon
                      alt={`preview-${index}`}
                      sx={{
                        width: { sm: "18px", xs: "16px" },
                        height: { sm: "18px", xs: "16px" },
                        display: "block",
                        cursor: "pointer",
                      }}
                      onClick={() => handleDelete(index)}
                    />
                    <Box
                      component="img"
                      src={previewFile} // Replace with your PDF icon
                      alt="preview"
                      sx={{
                        width: { sm: "18px", xs: "16px" },
                        height: { sm: "18px", xs: "16px" },
                        display: "block",
                        marginTop: "8px",
                        cursor: "pointer",
                      }}
                      onClick={() => handlePreview(file)}
                    />
                  </Box>
                </Box>
              ))}
            </Box>
            <Box
              sx={{
                mt: { xs: 2, sm: 2 },
                borderRadius: "12px",
                padding: { xs: "30px 20px", sm: "40px", lg: "50px" },
                textAlign: "center",
                backgroundColor: isDragActive
                  ? "rgba(65, 101, 246, 0.06)"
                  : "#FFF8F0", // Change background color when dragging
                transition: "background-color 0.3s ease", // Smooth transition
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <Box
                component="img"
                src={consultUpload}
                alt="Upload"
                sx={{ width: 48, height: 48 }}
              />
              <Typography
                sx={{
                  fontSize: { sm: 18, xs: 17 },
                  fontFamily: "Gilroy-Medium",
                  color: "rgba(28, 33, 45, 0.7)",
                  marginTop: "5px",
                }}
              >
                {t("consultField.dragFile")}
              </Typography>
              <Typography
                sx={{
                  fontSize: { sm: 16, xs: 14 },
                  fontFamily: "Gilroy-Medium",
                  color: "rgba(33, 33, 33, 0.4)",
                }}
              >
                {t("consultField.supportFile")}
              </Typography>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "primary.main",
                  textAlign: "center",
                  borderRadius: "6px",
                  py: { sm: 1.2, xs: 1 },
                  px: { sm: 5, xs: 3 },
                  color: "#fff",
                  textDecoration: "none",
                  fontSize: { sm: 18, xs: 16 },
                  fontFamily: "Gilroy-Medium",
                  textTransform: "capitalize",
                  margin: "10px",
                }}
              >
                {t("consultField.chooseFile")}
              </Button>
              <Typography
                sx={{
                  fontSize: 16,
                  fontFamily: "Gilroy-Medium",
                  color: "rgba(33, 33, 33, 0.7)",
                  display: { md: "block", lg: "none" },
                }}
              >
                Or
              </Typography>
              <Button
                variant="contained"
                startIcon={<CameraAltIcon />}
                onClick={(event) => {
                  event.stopPropagation();
                  setShowWebcam(true);
                }}
                sx={{
                  display: { md: "flex", lg: "none" },
                  margin: "5px 0 15px 0",
                  textAlign: "center",
                  borderRadius: "6px",
                  py: { sm: 1.2, xs: 1 },
                  px: { sm: 5, xs: 3 },
                  color: "#fff",
                  textDecoration: "none",
                  fontSize: { sm: 18, xs: 16 },
                  fontFamily: "Gilroy-Medium",
                  textTransform: "capitalize",
                  "& svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root":
                    {
                      fontSize: "24px !important",
                    },
                }}
              >
                {t("consultField.capture")}
              </Button>
              <Typography
                sx={{
                  fontSize: 14,
                  fontFamily: "Gilroy-Medium",
                  color: "rgba(33, 33, 33, 0.4)",
                }}
              >
                {t("consultField.maxFile")}
              </Typography>
            </Box>
            {dropError && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {dropError}
              </Alert>
            )}
            <Box
              sx={{
                display: "flex",
                alignItems: "start",
                marginTop: 2,
                gap: "12px",
                flexWrap: { xs: "wrap", sm: "wrap", lg: "nowrap" },
                flexDirection: { xs: "column", sm: "column", lg: "row" },
              }}
            >
              <Typography
                sx={{
                  fontSize: 14,
                  fontFamily: "Gilroy-Medium",
                  color: "rgba(33, 33, 33, 0.7)",
                  width: "350px ",
                }}
              >
                {t("consultField.listOfDocuments")}
              </Typography>
              <Typography
                sx={{
                  fontSize: 14,
                  fontFamily: "Gilroy-Medium",
                  color: "rgba(33, 33, 33, 0.4)",
                }}
              >
                {t("consultField.documentList")}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              marginTop: "24px",
            }}
          >
            <Button
              onClick={handleSubmit}
              variant="contained"
              sx={{
                mt: 2,
                backgroundColor: "primary.main",
                textAlign: "center",
                borderRadius: "8px",
                py: { sm: 1.2, xs: 1 },
                px: { sm: 5, xs: 3 },
                color: "#fff",
                textDecoration: "none",
                fontSize: { sm: 18, xs: 16 },
                fontFamily: "Gilroy-Medium",
                textTransform: "capitalize",
              }}
            >
              {t("consultField.submit")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default DocUploadModal;
