import React, { useState, useEffect, useMemo } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  InputAdornment,
  Autocomplete
} from "@mui/material";
import styled from "styled-components";
import { Search as SearchIcon } from "lucide-react";
import { serverRequest, serverRequest2 } from "../../utils/requestHelper";
import { getCookiesList } from "../../utils/cookiesHelper";
import { encryptData } from "../../utils/encryptionHelper";
import { useTranslation } from "react-i18next";

const SearchButton = styled.button`
  background-color: #FF8D28;
  border-radius: 8px;
  height: 56px;
  width: 56px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const FiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  flex-wrap: wrap;

  @media (max-width: 1100px) {
    & > div:last-of-type {
      flex: 0 1 calc(100% - 72px);
    }
    
    & > div:not(:last-of-type) {
      flex: 1 1 100%;
    }

    & > button {
      flex: 0 0 56px;
      margin-left: auto;
    }
  }
`;

const StyledFormControl = styled(FormControl)`
  width: 310px;
  
  @media (max-width: 1100px) {
    width: 100%;
  }
`;

const AuthPanelFilter = ({
  setPanelList,
  setpanelList1,
  setGroupPanelList,
  setGroupPanelList1,
  itemsPerPage,
  fetchPanelList,
  candidateEmail,
  setCandidateEmail,
  isUpdateClick
}) => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedZone, setSelectedZone] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");
  const [userSearchTerm, setUserSearchTerm] = useState("");
  const [clients, setClients] = useState([]);
  const [zones, setZones] = useState([]);
  const [branches, setBranches] = useState([]);
  const [users, setUsers] = useState([]);
  const [cookieList, setCookieList] = useState([]);

  useEffect(() => {
    const userCookiesList = getCookiesList("userInfo");
    if (userCookiesList.length > 0) {
      setCookieList(userCookiesList);
    }
  }, []);

  useEffect(() => {
    if (isUpdateClick) {
      setSelectedZone("");
      setSelectedBranch("");
      setCandidateEmail("");
      setZones([]);
      setBranches([]);
      setUsers([]);
    }
  }, [isUpdateClick]);

  const sortedAndFilteredUsers = useMemo(() => {
    return users
      .sort((a, b) => a.email.localeCompare(b.email))
      .filter(user =>
        user.email.toLowerCase().includes(userSearchTerm.toLowerCase())
      );
  }, [users, userSearchTerm]);

  useEffect(() => {
    if (selectedZone || selectedBranch || candidateEmail) {
      const data = {
        zone: selectedZone,
        branch: selectedBranch,
        user: candidateEmail,
      };
      setSearchQuery(data);
    }
  }, [selectedZone, selectedBranch, candidateEmail]);

  const fetchClients = async () => {
    const endpoint = process.env.REACT_APP_BASE_URL2 + "perfil/clientes";
    if (cookieList[6]) {
      const data_eid = encryptData(cookieList[6], 500);
      const enc_id = {
        data_eid: {
          data_eidi: data_eid.iv,
          data_eids: data_eid.salt,
          data_eidc: data_eid.ct,
        },
      };
      const res = await serverRequest2("post", endpoint, enc_id);
      if (Array.isArray(res.data)) {
        setClients(res.data.map((option) => ({ name: option })));
      }
    }
  };

  const fetchUsers = async () => {
    if ((cookieList[12] === 'manager' || cookieList[12] === 'admin') && cookieList[13]) {
      try {
        const data = {
          email: cookieList[3],
          role: cookieList[12],
          company_name: cookieList[13],
        };
        const response = await serverRequest("post", "/todos-usuario", data);
        if (Array.isArray(response?.data)) {
          setUsers(response.data);
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    }
  };

  const fetchZones = async () => {
    if (cookieList[6]) {
      const data_eid = encryptData(cookieList[6], 500);
      const enc_id = {
        data_eid: {
          data_eidi: data_eid.iv,
          data_eids: data_eid.salt,
          data_eidc: data_eid.ct,
        },
      };
      const res = await serverRequest("post", "perfil/di_zonas", enc_id);
      if (Array.isArray(res?.data)) {
        setZones(res.data.map((option) => ({ name: option })));
      }
    }
  };

  const fetchBranches = async () => {
    if (cookieList[6]) {
      const data_eid = encryptData(cookieList[6], 500);
      const enc_id = {
        data_eid: {
          data_eidi: data_eid.iv,
          data_eids: data_eid.salt,
          data_eidc: data_eid.ct,
        },
      };
      const res = await serverRequest("post", "perfil/sucursales", enc_id);
      if (Array.isArray(res.data)) {
        setBranches(res.data.map((option) => ({ name: option })));
      }
    }
  };

  useEffect(() => {
    if (cookieList.length !== 0) {
      fetchUsers();
      fetchClients();
      fetchZones();
      fetchBranches();
    }
  }, [cookieList]);

  const runSearchQuery = async (searchName, event) => {
    setPanelList([]);
    setpanelList1([]);
    setGroupPanelList([]);
    setGroupPanelList1([]);

    let zoneName = searchName === "zone" ? event : "";
    let branchName = searchName === "branch" ? event : "";
    let emailName = searchName === "email" ? event : "";

    if (emailName) {
      localStorage.setItem("selectedEmail", emailName);
    }

    const queryParams = {
      zone: zoneName || selectedZone,
      branch: branchName || selectedBranch,
      cand_email: emailName || candidateEmail,
    };

    if (queryParams.zone || queryParams.branch || queryParams.cand_email) {
      await fetchPanelList(1, 100, queryParams);
    }
  };

  useEffect(() => {
    return () => {
      localStorage.removeItem('selectedEmail');
      setCandidateEmail('');
    };
  }, []);

  return (
    <div className="w-full bg-white rounded-lg p-4">
      <FiltersContainer>
        {zones.length > 0 && (
          <StyledFormControl>
            <InputLabel>{t("authorizationpanel.summary.SelectZone")}</InputLabel>
            <Select
              value={selectedZone}
              onChange={(e) => {
                setSelectedZone(e.target.value);
                runSearchQuery("zone", e.target.value);
              }}
            >
              {zones.map((zone, index) => (
                <MenuItem key={index} value={zone.name}>
                  {zone.name}
                </MenuItem>
              ))}
            </Select>
          </StyledFormControl>
        )}

        {branches.length > 0 && (
          <StyledFormControl disabled={!selectedZone}>
            <InputLabel>{t("authorizationpanel.summary.SelectBranch")}</InputLabel>
            <Select
              value={selectedBranch}
              onChange={(e) => {
                setSelectedBranch(e.target.value);
                runSearchQuery("branch", e.target.value);
              }}
            >
              {branches.map((branch, index) => (
                <MenuItem key={index} value={branch.name}>
                  {branch.name}
                </MenuItem>
              ))}
            </Select>
          </StyledFormControl>
        )}

        <StyledFormControl>
          <Autocomplete
            value={candidateEmail}
            onChange={(event, newValue) => {
              setCandidateEmail(newValue);
              if (newValue) {
                runSearchQuery("email", newValue);
              }
            }}
            onInputChange={(event, newInputValue) => {
              setUserSearchTerm(newInputValue);
            }}
            options={sortedAndFilteredUsers.map(user => user.email)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("authorizationpanel.summary.SelectUser")}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon size={20} />
                    </InputAdornment>
                  ),
                }}
              />
            )}
            freeSolo
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
          />
        </StyledFormControl>
      </FiltersContainer>
    </div>
  );
};

export default AuthPanelFilter;