import React, { useState, useEffect, useRef } from "react";
import Modal from "@mui/material/Modal";
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button } from '@mui/material';
import { Fragment } from "react";
import { borderColor, textAlign } from "@mui/system";
import { Link, useNavigate, useParams } from "react-router-dom";
import mailImage from "../../assets/images/mailIcon.png";
import LabelInputLeftIcon from "../@common/Input/LabelInputLeftIcon";
import FormCheckboxes from "../@common/Input/chexboxes";
import underLine from "../../assets/images/under-line.png";
import HeadingWithIcon from "../@common/HeadingWithIcon/HeadingWithIcon";
import { Checkbox } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import phoneIcon from "../../assets/images/phoneIcon.png";
import SixDigitCode from "../@common/Input/SixDigitCode/SixDigitCode";
import { getCookiesList } from "../../utils/cookiesHelper";
import {
  dczVerificarLegal,
  call_api_function,
} from "../../utils/verifyAPICalls";
import {
  checkCupons,
  getReport,
  validateCupons,
} from "../../utils/bureauHelper";
import AlertModal from "../@common/Modal/AlertModal";
import { emailPattern } from "../../utils/InputPatterns";
import LogoModalLoader from "../@common/Modal/WithBureauModal/LogoModalLoader.js";
import CustomSpinner from "../@common/CustomSpinner/customSpinner";
import ThankYouMessage from "../@common/Modal/ThankYouMessage";
import { serverRequest2 } from "../../utils/requestHelper";
import HomonymsModal from "../@common/Modal/HomonymsModal";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";


export default function Autorizar(props) {
  const search_params = new URLSearchParams(window.location.search);
  const email = search_params.get("email");
  const phone = search_params.get("phone");
  const vlid = search_params.get("vlid");
  const geo_location = search_params.get("geo_location");
  const faceid = search_params.get("faceid");
  const authed = search_params.get("authed");

  const { t } = useTranslation();
  const theme = useTheme();
  const isMdOrLess = useMediaQuery(theme.breakpoints.down("md")); // Check if screen size is md or smaller

  const style = {
    outline: "none",
    border: 0,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    maxWidth: "95%",
    maxHeight: "90vh",
    overflowX: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 1,
  };

  const {
    tagLine = t("guestTermsCondition.tagline"),
    emailLabel = t("guestTermsCondition.emailLabel"),
    guestUser = false,
  } = props;
  //cookie state
  const [cookieList, setCookieList] = useState([]);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isCheckboxCheckedSecond, setIsCheckboxCheckedSecond] = useState(false);
  const [subjectEmail, setSubjectEmail] = useState("");
  const [personaPresente, setPersonaPresente] = useState(
    guestUser ? true : false
  );
  const [tagShow, setTagShow] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [isButtonClicked1, setIsButtonClicked1] = useState(false);
  const [subjectFoundSelect, setSubjectFoundSelect] = useState("");
  const [termsConditionSelect, setTermsConditionSelect] = useState("");
  const [termsConditionShow, setTermsConditionShow] = useState(true);
  const [authorizationShow, setauthorizationShow] = useState(false);
  const [showAlert, setShowAlert] = useState(false); // Add a state to control the visibility of the alert
  const [showtext, setShowtext] = useState("");
  const [emailButtonShow, setEmailButtonShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [isLoadingModal, setIsLoadingModal] = useState(false);
  const [spinnerVisibility, setSpinnerVisibility] = useState(false);
  const [isThankYouModalOpen, setIsThankYouModalOpen] = useState(false);

  const handleCloseAlert = () => {
    setShowAlert(false);
  };
  // handle modal close functions
  const handleClose = () => {
    setSubjectEmail("");
    setTagShow(true);
    setPersonaPresente(guestUser ? true : false);
    setSubjectFoundSelect("");
    setTermsConditionShow(false);
    setEmailButtonShow(false);
    setauthorizationShow(false);
    setIsCheckboxChecked(false);
    setIsButtonClicked(false);
    setIsCheckboxCheckedSecond(false);
    setIsButtonClicked1(false);
    // setEmailSent(false);
    // setIsLoading(false);
    setIsButtonClicked1(false);
    setSpinnerVisibility(false);
    setIsOpen(false);
  };

  const handleCheckboxChange = (e) => {
    if (!isCheckboxChecked) {
      // Only allow checking the checkbox if it's not already checked
      setIsCheckboxChecked(e.target.checked);
    }
  };

  useEffect(() => {
    const userCookiesList = getCookiesList("userInfo");
    if (userCookiesList.length > 0) {
      setCookieList(userCookiesList);
    }
  }, []);

  const termsConditiontHandler = (e) => {
    setTermsConditionSelect(
      termsConditionSelect == e.target.value ? "" : e.target.value
    );
  };

  // responseError Function
  const handleResponseError = (e) => {
    if (
      e.message.includes("timeout of 61000ms exceeded") ||
      e.message.includes("timeout of 20000ms exceeded") ||
      e.message.includes("timeout of 71000 exceeded")
    ) {
      setShowAlert(true);
      setShowtext("Alcanzaste el tiempo límite de espera. Intenta de nuevo.");
    } else {
      setShowAlert(true);
      setShowtext(t("thanks.check"));
    }
  };

  //Send Mail and Open Thank you modal function
  const sendMailAndEmitThankyouMessage = () => {
    handleClose();
    // open than you modal
    setIsThankYouModalOpen(true);
  };
  const handleInputEmailChange = (e) => {
    const inputValue = e.target.value;
    setSubjectEmail(inputValue);

    // Use the test method to check if the input matches the email pattern
    if (emailPattern.test(inputValue)) {
      setEmailButtonShow(true);
    } else {
      setEmailButtonShow(false);
    }
  };

  const scrollTargetRef = useRef(null);

  useEffect(() => {
    if (scrollTargetRef.current) {
      scrollTargetRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [termsConditionShow, authorizationShow]);

  // Function for authorization and navigate to report
  const authorizeHandler = async () => {
    setIsButtonClicked1(true);
    setSpinnerVisibility(true);
    var dataAutSBPP = {
      oid: vlid
    };
    try {
      const response = await serverRequest2(
        "post",
        "autorizar_ppsb",
        dataAutSBPP
      );
      if (response.data == "validado") {
        handleClose();
        sendMailAndEmitThankyouMessage();
        return;
      }
    } catch (e) {
      setSpinnerVisibility(false);
      handleClose(); //close the modal
      handleResponseError(e);
    }
  };
  const acceptHandler = () => {
    setauthorizationShow(true);
    setIsButtonClicked(true);
  };

  return (
    <Fragment>
      {showAlert && <AlertModal text={showtext} onClose={handleCloseAlert} />}
      <LogoModalLoader isOpenLogoModal={isLoadingModal} />
      <CustomSpinner visible={spinnerVisibility} />
      <ThankYouMessage
        isOpen={isThankYouModalOpen}
        setIsOpen={setIsThankYouModalOpen}
        path={"/legal-email" + window.location.search}
      />
      <Modal
        open={isOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            sx={{
              fontSize: 16,
              fontFamily: "Gilroy-Medium",
              color: "rgba(27, 32, 44, 0.6)",
              textAlign: "center",
              mb: 4,
            }}
          >
            {tagLine}
          </Typography>

          <Box
            sx={{
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: "rgba(27, 32, 44, 0.1)",
              borderRadius: "6px",
              p: 2,
            }}
          >
            <Box>
              <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                <Typography
                  sx={{
                    color: "neutral.900",
                    fontSize: 16,
                    fontFamily: "Gilroy-Medium",
                    textAlign: "center",
                  }}
                >
                  {tagShow
                    ? emailLabel
                    : t("guestTermsCondition.emailLabelpin")}
                </Typography>
              </Box>
              <Box sx={{ width: { xs: "90%", sm: "70%" }, mx: "auto", pt: 2 }}>
                <LabelInputLeftIcon
                  mailIcon={mailImage}
                  type="text"
                  label=""
                  name="emailAddress"
                  id="emailAddress"
                  inputValue={email}
                  disabled={true}
                  placeholder={t("placeholders.email")}
                  onChange={handleInputEmailChange}
                />
              </Box>
            </Box>
          </Box>

          {termsConditionShow ? (
            <>
              <div ref={scrollTargetRef}>
                <Box sx={{ my: 4 }}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isCheckboxChecked}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label={
                        <Box component={"span"} sx={{ color: "#4165F6" }}>
                          {t("guestTermsCondition.accept")}
                          <Box component={"span"} sx={{ color: "gray", ml: 1 }}>
                            <a
                              href="/PrivacyNotice"
                              target={"_blank"}
                              style={{ color: "#656DFC" }}
                            >
                              {`${t("guestTermsCondition.Privacy Notice")} `}
                            </a>
                            &
                            <a
                              href="/TermsAndConditions"
                              target={"_blank"}
                              style={{ color: "#656DFC" }}
                            >
                              {` ${t(
                                "guestTermsCondition.Terms and Conditions"
                              )}`}
                            </a>
                            {` ${t("guestTermsCondition.newTerm1")}`}
                            {` ${t("guestTermsCondition.newTerm2")} `}
                            <a
                              href="https://micuenta.infonavit.org.mx/"
                              target={"_blank"}
                              style={{ color: "#656DFC" }}
                              rel="noreferrer"
                            >
                              {`(https://micuenta.infonavit.org.mx/)`}
                            </a>
                            {` ${t("guestTermsCondition.newTerm3")} `}
                            <a
                              href="https://serviciosdigitales.imss.gob.mx/semanascotizadas-web/"
                              target={"_blank"}
                              style={{ color: "#656DFC" }}
                              rel="noreferrer"
                            >
                              {`(https://serviciosdigitales.imss.gob.mx/semanascotizadas-web/)`}
                            </a>
                            {` ${t("guestTermsCondition.newTerm4")} `}
                          </Box>
                        </Box>
                      }
                    />
                  </FormGroup>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      mt: 4,
                    }}
                  >
                    <Button
                      onClick={acceptHandler}
                      sx={{
                        backgroundColor: "primary.main",
                        py: 1,
                        px: { xs: 4, sm: 8 },
                        fontSize: "18px",
                        fontFamily: "Gilroy-Medium",
                        textTransform: "capitalize",
                      }}
                      variant="contained"
                      disabled={!isCheckboxChecked || isButtonClicked}
                    >
                      {t("buttons.Accept")}
                    </Button>
                  </Box>
                </Box>
              </div>
            </>
          ) : null}
          {authorizationShow ? (
            <>
              <div ref={scrollTargetRef}>
                <hr
                  style={{
                    background: "rgba(255, 255, 255, 0.2)",
                    borderColor: "rgba(255, 255, 255, 0.2)",
                  }}
                />
                <Box sx={{ mt: 2, mb: 2 }}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked={isCheckboxCheckedSecond}
                          onChange={(e) =>
                            setIsCheckboxCheckedSecond(e.target.checked)
                          }
                        />
                      }
                      label={
                        <Box component={"span"} sx={{ color: "#4165F6" }}>
                          {t("guestTermsCondition.authorization")}
                          <Box component={"span"} sx={{ color: "gray", ml: 1 }}>
                            {t("guestTermsCondition.authorization1")}
                          </Box>
                        </Box>
                      }
                    />
                  </FormGroup>
                  <Typography
                    sx={{
                      fontSize: 16,
                      color: "text.opacity",
                      fontFamily: "Gilroy-Medium",
                      mt: 4,
                    }}
                  >
                    {t("guestTermsCondition.authorization2")}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    mt: 4,
                  }}
                >
                  <Button
                    onClick={authorizeHandler}
                    sx={{
                      backgroundColor: "primary.main",
                      py: 1,
                      px: { xs: 4, sm: 8 },
                      fontSize: "18px",
                      fontFamily: "Gilroy-Medium",
                      textTransform: "capitalize",
                    }}
                    variant="contained"
                    disabled={!isCheckboxCheckedSecond || isButtonClicked1}
                  >
                    {t("buttons.Authorize")}
                  </Button>
                </Box>
              </div>
            </>
          ) : null}
        </Box>
      </Modal>
    </Fragment>
  );
}
