import { Box, Typography } from "@mui/material";
import React from "react";
import imgReport from "../../../assets/images/avatar.svg";
import lineImg from "../../../assets/images/line-header-sec.png";
import { useTranslation } from "react-i18next";

function ReportHeader({
  Curp,
  RFC,
  Age,
  Name,
  Date,
  NSS,
  Total_Homonimos,
  Face_ID_Exists,
  Face_ID_Status,
  Face_ID_Color,
  imageShow = true,
}) {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        px: 2,
        py: 2,
        fontSize: "7px !important",
        display: "flex",
        alignItems: "center",
        flexDirection: { xs: "row", sm: "row" },
        flexWrap: { xs: "wrap", sm: "nowrap" },
        boxShadow: 4,
        rowGap: { xs: 1, sm: 0 },
        columnGap: { xs: 3, sm: 0 },
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        {imageShow && (
          <img src={imgReport} width={70} height={70} alt="image report" />
        )}

        <Box sx={{ ml: { xs: 1, md: 2 } }}>
          <Typography
            sx={{
              fontSize: { xs: 12, md: 16 },
              fontFamily: "Gilroy-SemiBold",
              color: "neutral.900",
            }}
          >
            {Name}
          </Typography>
          <Box
            sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}
          >
            <Typography
              sx={{
                fontSize: { xs: 12, md: 16 },
                fontFamily: "Gilroy-Medium",
                color: "text.opacity",
              }}
            >
              {Age != "undefined" ? Age : ""} {t("thanks.Years")}
            </Typography>
            <Box
              sx={{
                borderRight: "solid 1px rgba(27, 32, 44, 0.2)",
                mx: { xs: 0.4, md: 2 },
                height: 16,
                mt: "4px",
              }}
            ></Box>
            <Typography
              sx={{
                fontSize: { xs: 12, md: 16 },
                fontFamily: "Gilroy-Medium",
                color: "primary.main",
              }}
            >
              ({Total_Homonimos} {t("thanks.Homonyms")})
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: { xs: "none", sm: "flex" },
          mx: { xs: 0.6, md: 4 },
          mt: { xs: 1.6, md: 1.2 },
        }}
      >
        <img src={lineImg} height={50} alt="line" />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: 1,
          width: { xs: "45%", sm: "auto" },
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: 12, md: 16 },
            fontFamily: "Gilroy-SemiBold",
            color: "neutral.900",
          }}
        >
          {t("thanks.Date")}
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: 12, md: 16 },
            fontFamily: "Gilroy-Medium",
            color: "text.opacity",
          }}
        >
          {Date}
        </Typography>
      </Box>
      <Box
        sx={{
          display: { xs: "none", sm: "flex" },
          mx: { xs: 0.6, md: 4 },
          mt: { xs: 1.6, md: 1.2 },
        }}
      >
        <img src={lineImg} height={50} alt="line" />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: 1,
          width: { xs: "45%", sm: "auto" },
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: 12, md: 16 },
            fontFamily: "Gilroy-SemiBold",
            color: "neutral.900",
          }}
        >
          Curp
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: 12, md: 16 },
            fontFamily: "Gilroy-Medium",
            color: "text.opacity",
          }}
        >
          {Curp}
        </Typography>
      </Box>
      <Box
        sx={{
          display: { xs: "none", sm: "flex" },
          mx: { xs: 0.6, md: 4 },
          mt: { xs: 1.6, md: 1.2 },
        }}
      >
        <img src={lineImg} height={50} alt="line" />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: 1,
          width: { xs: "45%", sm: "auto" },
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: 12, md: 16 },
            fontFamily: "Gilroy-SemiBold",
            color: "neutral.900",
          }}
        >
          R.F.C.
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: 12, md: 16 },
            fontFamily: "Gilroy-Medium",
            color: "text.opacity",
          }}
        >
          {RFC}
        </Typography>
      </Box>
      <Box
        sx={{
          display: { xs: "none", sm: "flex" },
          mx: { xs: 0.6, md: 4 },
          mt: { xs: 1.6, md: 1.2 },
        }}
      >
        <img src={lineImg} height={50} alt="line" />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: 1,
          width: { xs: "45%", sm: "auto" },
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: 12, md: 16 },
            fontFamily: "Gilroy-SemiBold",
            color: "neutral.900",
          }}
        >
          NSS
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: 12, md: 16 },
            fontFamily: "Gilroy-Medium",
            color: "text.opacity",
          }}
        >
          {NSS}
        </Typography>
      </Box>
      {
        Face_ID_Exists && (
          <>
            <Box
              sx={{
                display: { xs: "none", sm: "flex" },
                mx: { xs: 0.6, md: 4 },
                mt: { xs: 1.6, md: 1.2 },
              }}
            >
              <img src={lineImg} height={50} alt="line" />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                mt: 1,
                width: { xs: "45%", sm: "auto" },
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: 12, md: 16 },
                  fontFamily: "Gilroy-Bold",
                  backgroundColor: Face_ID_Color,
                  color: "white",
                  borderRadius: "6px",
                  padding: "5px",
                }}
              >
                {Face_ID_Status}
              </Typography>
            </Box>
          </>
        )
      }
    </Box>
  );
}

export default ReportHeader;
