import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import mailImage from "../../assets/images/mailIcon.png";
import LabelInputLeftIcon from "../@common/Input/LabelInputLeftIcon";
import underLine from "../../assets/images/under-line.png";
import HeadingWithIcon from "../@common/HeadingWithIcon/HeadingWithIcon";
import { Checkbox } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import phoneIcon from "../../assets/images/phoneIcon.png";
import SixDigitCode from "../@common/Input/SixDigitCode/SixDigitCode";
import { getCookiesList } from "../../utils/cookiesHelper";
import {
  dczVerificarLegal,
  call_api_function,
} from "../../utils/verifyAPICalls";
import { emailPattern, phoneNumberPattern } from "../../utils/InputPatterns";
import AlertModal from "../@common/Modal/AlertModal";
import CustomSpinner from "../@common/CustomSpinner/customSpinner";
import ThankYouMessage from "../@common/Modal/ThankYouMessage";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";


export default function LegalNip(props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    emailLabel = t("guestTermsCondition.tagline"),
    mobileLabel = t("guestTermsCondition.emailLabel"),
    dataCurpZero,
    nbData,
    subsections_dicts,
  } = props;


  const style = {
    outline: "none",
    border: 0,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    maxWidth: "95%",
    maxHeight: "90vh",
    overflowX: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 1,
  };

  const navigate = useNavigate();

  const search_params = new URLSearchParams(window.location.search);
  const email = search_params.get("email");
  const phone = search_params.get("phone");
  const vlid = search_params.get("vlid");
  const geo_location = search_params.get("geo_location");
  const faceid = search_params.get("faceid");
  const authed = search_params.get("authed");

  const phone_code = phone.substring(0, phone.length - 10) || "+52";
  const phone_number = phone.substring(phone.length - 10, phone.length);
  const is_empresa = search_params.get("is_empresa");

  //cookie state
  const [cookieList, setCookieList] = useState([]);
  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  const [showAlert, setShowAlert] = useState(false); // Add a state to control the visibility of the alert
  const [showtext, setShowtext] = useState("");
  const handleClose = () => {
    setTagShow(true);
    setPersonaPresente(false);
    setSubjectFoundSelect("");
    setEmailButtonShow(true);
    setSubjectEmail("");
    setSubjectPhone("");
    setauthorizationShow(false);
    setIsCheckboxChecked(false);
    setIsButtonClicked(false);
    setIsCheckboxCheckedSecond(false);
    setTermsConditionShow(false);
    setIsOpen(false);
  };
  const [subjectFoundSelect, setSubjectFoundSelect] = useState("");
  const [termsConditionSelect, setTermsConditionSelect] = useState("");
  const [termsConditionShow, setTermsConditionShow] = useState(true);
  const [subjectEmail, setSubjectEmail] = useState(email);
  const [subjectPhone, setSubjectPhone] = useState(phone_number);
  const [prefix, setPrefix] = useState(phone_code);
  const [nip, setNip] = useState("");
  const [personaPresente, setPersonaPresente] = useState(false);
  const [tagShow, setTagShow] = useState(false);
  const [authorizationShow, setauthorizationShow] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isCheckboxCheckedSecond, setIsCheckboxCheckedSecond] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [emailButtonShow, setEmailButtonShow] = useState(true);
  const [isOpen, setIsOpen] = useState(true);
  const [spinnerVisibility, setSpinnerVisibility] = useState(false);
  const [isLoadingModal, setIsLoadingModal] = useState(false);
  const [isThankYouModalOpen, setIsThankYouModalOpen] = useState(false);

  // Side Effects
  useEffect(() => {
    const userCookiesList = getCookiesList("userInfo");
    if (userCookiesList.length > 0) {
      setCookieList(userCookiesList);
    }
  }, []);

  const handleCheckboxChange = (e) => {
    if (!isCheckboxChecked) {
      // Only allow checking the checkbox if it's not already checked
      setIsCheckboxChecked(e.target.checked);
    }
  };

  const handleResponseError = (e) => {
    if (
      e.message.includes("timeout of 61000ms exceeded") ||
      e.message.includes("timeout of 20000ms exceeded") ||
      e.message.includes("timeout of 71000 exceeded")
    ) {
      setShowAlert(true);
      setShowtext("Alcanzaste el tiempo límite de espera. Intenta de nuevo.");
    } else {
      setShowAlert(true);
      setShowtext(t("thanks.check"));
    }
  };

  const isValidVerificationCode = (value) => {
    // perform verification logic
    return value.length === 6 && /^\d+$/.test(value);
  };

  const sendMailAndEmitThankyouMessage = () => {
    handleClose();
    setIsThankYouModalOpen(true);
  };

  const verificarNip = async () => {
    if (isValidVerificationCode(nip)) {
      console.log(isValidVerificationCode(nip));
      var dataLegal = {
        mail: email,
        phono: phone_code + phone_number,
        nip: nip,
        vlid: vlid
      };
      var formData = new FormData();

      // Append each key-value pair from dataLegal to the FormData object
      for (var key in dataLegal) {
        formData.append(key, dataLegal[key]);
      }
      try {
        const response = await call_api_function(
          process.env.REACT_APP_BASE_URL2 + (is_empresa ? "legal-nip_e_module" : "legal-nip-auth"),
          formData
        );
        if (response.data == "nip incorrecto") {
          setSpinnerVisibility(false);
          setShowAlert(true);
          setShowtext(t("guestTermsCondition.IncorrectPIN"));
        } else if(response.data === false) {
            setSpinnerVisibility(false)
            setShowAlert(true);
            setShowtext("Invalid NIP");
        }
        else if (response.data) {
            setSpinnerVisibility(false)
            sendMailAndEmitThankyouMessage()
        }
      } catch (e) {
        setSpinnerVisibility(false);
        handleResponseError(e);
      }
    } else {
      setSpinnerVisibility(false);
      setShowAlert(true);
      setShowtext("kindly enter the PIN");
    }
  };

  const acceptHandler = () => {
    setauthorizationShow(true);
    setIsButtonClicked(true);
  };

  const authorizeHandler = () => {
    setSpinnerVisibility(true);
    verificarNip();
  };
  const handlePhoneChange = (event) => {
    const inputValue = event.target.value;
    setSubjectPhone(inputValue);
    validateForm(inputValue, subjectEmail);
  };

  const handleEmailChange = (event) => {
    const inputValue = event.target.value;
    setSubjectEmail(inputValue);
    validateForm(subjectPhone, inputValue);
  };

  const validateForm = (phone, email) => {
    if (
      phoneNumberPattern.test(phone) &&
      emailPattern.test(email) &&
      phone.trim() !== "" &&
      email.trim() !== ""
    ) {
      setEmailButtonShow(false); // Both are valid and not empty, enable the button
    } else {
      setEmailButtonShow(true);
    }
  };
  const handleCloseAlert = () => {
    setShowAlert(false);
  };
  const scrollTargetRef = useRef(null);

  useEffect(() => {
    if (scrollTargetRef.current) {
      scrollTargetRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [termsConditionShow, authorizationShow]);

  return (
    <Fragment>
      <CustomSpinner visible={spinnerVisibility} />
      {showAlert && <AlertModal text={showtext} onClose={handleCloseAlert} />}
      <ThankYouMessage
        isOpen={isThankYouModalOpen}
        setIsOpen={setIsThankYouModalOpen}
        path={"/legal-email" + window.location.search}
      />
      <Modal
        open={isOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            sx={{
              fontSize: 16,
              fontFamily: "Gilroy-Medium",
              color: "rgba(27, 32, 44, 0.6)",
              textAlign: "center",
              mb: 4,
            }}
          >
            {t("guestTermsCondition.tagline")}
          </Typography>

          <Box
            sx={{
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: "rgba(27, 32, 44, 0.1)",
              borderRadius: "6px",
              p: 2,
              overflow: "auto"
            }}
          >
            <Box>
              <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                <Typography
                  sx={{
                    color: "neutral.900",
                    fontSize: 16,
                    fontFamily: "Gilroy-Medium",
                    textAlign: "center",
                  }}
                >
                  {tagShow ? mobileLabel : t("thanks.Cellnumberreceiving")}
                </Typography>
              </Box>
              <Box sx={{ width: { xs: "90%", sm: "70%" }, mx: "auto", pt: 2 }}>
                <LabelInputLeftIcon
                  isPhone={true}
                  prefix={prefix}
                  setPrefix={() => {}}
                  mailIcon={phoneIcon}
                  type="number"
                  label=""
                  name="mnumber"
                  id="mnumber"
                  placeholder="Enter mobile number"
                  inputValue={phone_number}
                  onChange={handlePhoneChange}
                  maxLength={10}
                />
              </Box>
            </Box>
            <Box>
              <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                <Typography
                  sx={{
                    color: "neutral.900",
                    fontSize: 16,
                    fontFamily: "Gilroy-Medium",
                    textAlign: "center",
                  }}
                >
                  {tagShow ? emailLabel : t("thanks.Email receipt")}
                </Typography>
              </Box>
              <Box sx={{ width: { xs: "90%", sm: "70%" }, mx: "auto", pt: 2 }}>
                <LabelInputLeftIcon
                  mailIcon={mailImage}
                  type="text"
                  label=""
                  name="emailAddress"
                  id="emailAddress"
                  placeholder="Enter your email address"
                  inputValue={email}
                  onChange={handleEmailChange}
                />
              </Box>
            </Box>
          </Box>
          {termsConditionShow ? (
            <>
              <div ref={scrollTargetRef}>
                <Box sx={{ my: 4 }}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isCheckboxChecked}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label={
                        <Box component={"span"} sx={{ color: "#4165F6" }}>
                          {t("guestTermsCondition.accept")}
                          <Box component={"span"} sx={{ color: "gray", ml: 1 }}>
                            <a
                              href="/PrivacyNotice"
                              target={"_blank"}
                              style={{ color: "#656DFC" }}
                            >
                              {`${t("guestTermsCondition.Privacy Notice")} `}
                            </a>
                            &
                            <a
                              href="/TermsAndConditions"
                              target={"_blank"}
                              style={{ color: "#656DFC" }}
                            >
                              {` ${t(
                                "guestTermsCondition.Terms and Conditions"
                              )}`}
                            </a>
                            {` ${t("guestTermsCondition.newTerm1")}`}
                            {` ${t("guestTermsCondition.newTerm2")} `}
                            <a
                              href="https://micuenta.infonavit.org.mx/"
                              target={"_blank"}
                              style={{ color: "#656DFC" }}
                              rel="noreferrer"
                            >
                              {`(https://micuenta.infonavit.org.mx/)`}
                            </a>
                            {` ${t("guestTermsCondition.newTerm3")} `}
                            <a
                              href="https://serviciosdigitales.imss.gob.mx/semanascotizadas-web/"
                              target={"_blank"}
                              style={{ color: "#656DFC" }}
                              rel="noreferrer"
                            >
                              {`(https://serviciosdigitales.imss.gob.mx/semanascotizadas-web/)`}
                            </a>
                            {` ${t("guestTermsCondition.newTerm4")} `}
                          </Box>
                        </Box>
                      }
                    />
                  </FormGroup>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      mt: 4,
                    }}
                  >
                    <Button
                      onClick={acceptHandler}
                      sx={{
                        backgroundColor: "primary.main",
                        py: 1,
                        px: { xs: 4, sm: 8 },
                        fontSize: "18px",
                        fontFamily: "Gilroy-Medium",
                        textTransform: "capitalize",
                      }}
                      variant="contained"
                      disabled={!isCheckboxChecked || isButtonClicked}
                    >
                      {t("buttons.Accept")}
                    </Button>
                  </Box>
                </Box>
              </div>
            </>
          ) : null}
          {authorizationShow ? (
            <>
              <div ref={scrollTargetRef}>
                <hr
                  style={{
                    background: "rgba(255, 255, 255, 0.2)",
                    borderColor: "rgba(255, 255, 255, 0.2)",
                  }}
                />
                <Box sx={{ mt: 2, mb: 2}}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked={isCheckboxCheckedSecond}
                          onChange={(e) =>
                            setIsCheckboxCheckedSecond(e.target.checked)
                          }
                        />
                      }
                      label={
                        <Box component={"span"} sx={{ color: "#4165F6" }}>
                          {" "}
                          {t("guestTermsCondition.authorization")}{" "}
                          <Box component={"span"} sx={{ color: "gray", ml: 1 }}>
                            {t("guestTermsCondition.authorization1")}
                          </Box>
                        </Box>
                      }
                    />
                  </FormGroup>
                  <Typography
                    sx={{
                      fontSize: 16,
                      color: "text.opacity",
                      fontFamily: "Gilroy-Medium",
                      mt: 4,
                    }}
                  >
                    {t("guestTermsCondition.authorization2")}
                  </Typography>
                </Box>
                <hr
                  style={{
                    background: "rgba(255, 255, 255, 0.2)",
                    borderColor: "rgba(255, 255, 255, 0.2)",
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 2,
                  }}
                >
                  <Typography
                    sx={{
                      color: "neutral.900",
                      fontSize: 16,
                      fontFamily: "Gilroy-Medium",
                      textAlign: "center",
                      mb: 2,
                    }}
                  >
                    {" "}
                    {t("guestTermsCondition.pinno")}
                  </Typography>
                  <SixDigitCode onCodeChange={(value) => setNip(value)} />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    mt: 4,
                  }}
                >
                  <Button
                    disabled={
                      !isCheckboxCheckedSecond || !isValidVerificationCode(nip)
                    }
                    onClick={authorizeHandler}
                    sx={{
                      backgroundColor: "primary.main",
                      py: 1,
                      px: { xs: 4, sm: 8 },
                      fontSize: "18px",
                      fontFamily: "Gilroy-Medium",
                      textTransform: "capitalize",
                    }}
                    variant="contained"
                  >
                    {" "}
                    {t("buttons.Authorize")}
                  </Button>
                </Box>
              </div>
            </>
          ) : null}
        </Box>
      </Modal>
    </Fragment>
  );
}
