import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Fragment } from "react";
import { borderColor, textAlign } from "@mui/system";
import { Link, useNavigate } from "react-router-dom";
import mailImage from "../../../../assets/images/mailIcon.png";
import LabelInputLeftIcon from "../../../@common/Input/LabelInputLeftIcon";
import FormCheckboxes from "../../../@common/Input/chexboxes";
import underLine from "../../../../assets/images/under-line.png";
import HeadingWithIcon from "../../HeadingWithIcon/HeadingWithIcon";
import { Checkbox, IconButton } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import phoneIcon from "../../../../assets/images/phoneIcon.png";
import SixDigitCode from "../../Input/SixDigitCode/SixDigitCode";
import { getCookiesList } from "../../../../utils/cookiesHelper";
import {
  dczVerificarLegal,
  call_api_function,
} from "../../../../utils/verifyAPICalls";
import {
  getPersonalization,
  checkCupons,
  getReport,
  validateCupons,
  translationHelper,
  verReport,
  userCupons,
  imprimir,
  translateNestedDict,
} from "../../../../utils/bureauHelper";
import AlertModal from "../../../@common/Modal/AlertModal";
import { emailPattern } from "../../../../utils/InputPatterns";
import LogoModalLoader from "../../../@common/Modal/WithBureauModal/LogoModalLoader.js";
import CustomSpinner from "../../../@common/CustomSpinner/customSpinner";
import { useTranslation } from "react-i18next";
import HomonymsModal from "../../../@common/Modal/HomonymsModal";
import { serverRequest2 } from "../../../../utils/requestHelper";
import { processCompanyData } from "../../../../utils/getColorHelper";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
const termsConditionList = ["The present subject is found"];
export default function GuestTermsAndConditionModal(props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMdOrLess = useMediaQuery(theme.breakpoints.down("md")); // Check if screen size is md or smaller
  const isBetween1024And1440 = useMediaQuery(
    `(min-width: 1024px) and (max-width: 1440px)`
  ); // Check if screen size is between 1024px and 1440px
  const isGreaterThan1440 = useMediaQuery(theme.breakpoints.up("xl")); // Check if screen size is greater than 1440px
  const style = {
    "*::-webkit-scrollbar": {
      width: "6px !important", // Adjust as needed
      borderRadius: "3px !important",
    },
    "*::-webkit-scrollbar-track": {
      backgroundColor: "#E4EFEF !important", // Adjust as needed
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "#4165f1d4 !important", // Adjust as needed
      borderRadius: "3px !important", // Rounded corners
    },
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    maxWidth: "95%",
    maxHeight: "90vh",
    overflowX: "auto",
    overflowY: "scroll",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 1
  };

  const {
    tagLine = t("guestTermsCondition.tagline"),
    tagLineShow = false,
    emailLabel = t("guestTermsCondition.emailLabel"),
    subjectCheckboxShow = false,
    emailBoxButtonShow = false,
    guestUser = false,
    dataCurpZero,
    nbData,
    onConfirmation,
    subsections_dicts,
    showGuestQueryForm,
    showHomonyms,
    nss1Data,
    nssData,
    estadoData,
    curpData,
    cname,
    edad,
    dczCurp,
    dczCurpId,
    dczCurpDoc,
    documentStatus,
  } = props;
  console.log(dczCurp , "dczCurp" ,dczCurpId , "dczCurpId" , dczCurpDoc , "dczCurpDocdfdfsdf" , documentStatus , "documentStatus")


  const navigate = useNavigate();
  //cookie state
  const [cookieList, setCookieList] = useState([]);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isCheckboxCheckedSecond, setIsCheckboxCheckedSecond] = useState(false);
  const [subjectEmail, setSubjectEmail] = useState("");
  const [personaPresente, setPersonaPresente] = useState(
    guestUser ? true : false
  );
  const [tagShow, setTagShow] = useState(true);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [isButtonClicked1, setIsButtonClicked1] = useState(false);
  const [subjectFoundSelect, setSubjectFoundSelect] = useState("");
  const [termsConditionSelect, setTermsConditionSelect] = useState("");
  const [termsConditionShow, setTermsConditionShow] = useState(false);
  const [authorizationShow, setauthorizationShow] = useState(false);
  const [showAlert, setShowAlert] = useState(false); // Add a state to control the visibility of the alert
  const [showtext, setShowtext] = useState("");
  const [emailButtonShow, setEmailButtonShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [isLoadingModal, setIsLoadingModal] = useState(false);
  const [spinnerVisibility, setSpinnerVisibility] = useState(false);
  const [showHomonymsmodal, setHomonymsmodal] = useState(false);


  const handleCloseAlert = () => {
    setShowAlert(false);
  };
  const handleHomonymsmodal = () => {
    setHomonymsmodal(false);
  };
  // handle modal close functions
  const handleClose = () => {
    setSubjectEmail("");
    setTagShow(true);
    setPersonaPresente(guestUser ? true : false);
    setSubjectFoundSelect("");
    setTermsConditionShow(false);
    setEmailButtonShow(false);
    setauthorizationShow(false);
    setIsCheckboxChecked(false);
    setIsButtonClicked(false);
    setIsCheckboxCheckedSecond(false);
    setIsButtonClicked1(false);
    setEmailSent(false);
    setIsLoading(false);
    setIsButtonClicked1(false);
    setSpinnerVisibility(false);
    props.setWithoutBureauModalOpen(false);
  };
  const handleCloseWithoutResetStates = () => {
    props.setWithoutBureauModalOpen(false);
  };

  const handleCheckboxChange = (e) => {
    if (!isCheckboxChecked) {
      // Only allow checking the checkbox if it's not already checked
      setIsCheckboxChecked(e.target.checked);
    }
  };

  // Side Effects
  useEffect(() => {}, [personaPresente]);

  useEffect(() => {
    const cookieKey = guestUser ? "guestUserInfo" : "userInfo";
    const userCookiesList = getCookiesList(cookieKey);
    if (userCookiesList.length > 0) {
      setCookieList(userCookiesList);
    }
  }, []);

  const subjectFoundHandler = (e) => {
    if (!guestUser) {
      setSubjectFoundSelect(
        subjectFoundSelect == e.target.value ? "" : e.target.value
      );
      setPersonaPresente(subjectFoundSelect == e.target.value ? false : true);
    }
  };

  const termsConditiontHandler = (e) => {
    setTermsConditionSelect(
      termsConditionSelect == e.target.value ? "" : e.target.value
    );
  };

  // responseError Function
  const handleResponseError = (e) => {
    if (
      e.message.includes("timeout of 61000ms exceeded") ||
      e.message.includes("timeout of 20000ms exceeded") ||
      e.message.includes("timeout of 71000 exceeded")
    ) {
      setShowAlert(true);
      setShowtext("Alcanzaste el tiempo límite de espera. Intenta de nuevo.");
    } else {
      setShowAlert(true);
      setShowtext(t("thanks.check"));
    }
  };

  //Send Mail and Open Thank you modal function
  const sendMailAndEmitThankyouMessage = (data) => {
    handleClose();
    onConfirmation(data, subsections_dicts);
  };
  const handleInputEmailChange = (e) => {
    const inputValue = e.target.value.replace(/\s/g, '');
    setSubjectEmail(inputValue);

    // Use the test method to check if the input matches the email pattern
    if (emailPattern.test(inputValue)) {
      setEmailButtonShow(true);
    } else {
      setEmailButtonShow(false);
    }
  };

  const subjectList = [
    {
      label: t("guestTermsCondition.subjectList"),
      value: "The present subject is found",
    },
  ];

  // Send Handler when send button is clicked
  const sendHandler = async () => {
    if (isLoading) {
      return; // Prevent multiple clicks while loading
    }
    // Set isLoading to true to show loading state
    setIsLoading(true);
    setIsLoadingModal(true);
    setTagShow(personaPresente);
    var dataLegalSB = new FormData();
    dataLegalSB.append("usrMail", cookieList[3]);
    dataLegalSB.append("mail", subjectEmail);
    dataLegalSB.append("phono", "no tel");
    dataLegalSB.append("caso", "");
    dataLegalSB.append("nbc_id", dczCurpId);
    dataLegalSB.append("geo_location", dataCurpZero["sujeto_geo_location"]);
    dataLegalSB.append("face_id", dataCurpZero["sujeto_face_id"]);

    if (!personaPresente) {
      props.setWithoutBureauModalOpen(false);
      dataLegalSB.set("caso", "caso1");
    } else {
      dataLegalSB.set("caso", "caso2");
    }
    try {
      const verifyResponse = await call_api_function(
        process.env.REACT_APP_BASE_URL2 + "legal-Person-auth",
        dataLegalSB
      );
      if (verifyResponse == "error bd") {
        setShowAlert(true);
        setShowtext("in,Conexión");
      } else if (verifyResponse.data) {
        const id_sujeto = verifyResponse.data;
        dataCurpZero["sujeto_id"] = id_sujeto;
        dataCurpZero["vl_id"] = verifyResponse.data;
        dataCurpZero["sujeto_idHomonimo"] = "0";
        dataCurpZero["id_NBC"] = nbData._id;
        dataCurpZero["secciones_consulta"] = JSON.stringify(subsections_dicts);
        dataCurpZero["id_NBC"] = nbData._id;
        dataCurpZero["curp"] = dczCurp;
        dataCurpZero["curp_Id"] = dczCurpId;
        // dataCurpZero["upload_Documents"] = JSON.stringify(dczCurpDoc);
        dataCurpZero["documents_status"] = JSON.stringify(documentStatus || {});

        const dCZ = dataCurpZero;
        const dczResponse = await dczVerificarLegal(dCZ);
        if (dczResponse.data) {
          const subsections_dict = dczResponse.data.secciones_consulta;
          if (dataLegalSB.get("caso") == "caso1") {
            handleClose();
            setIsLoadingModal(false);
            props.setEmailSentModalOpen(true);
          } else {
            setTermsConditionShow(true);
            setEmailSent(true);
            setIsLoadingModal(false);
          }
        } else {
          setIsLoadingModal(false);
          setShowAlert(true);
          setShowtext("error");
        }
      }
      setIsLoading(false);
    } catch (error) {
      if (error.message === "timeout of 30000ms exceeded") {
        setIsLoadingModal(false);
        setShowAlert(true);
        setShowtext("Alcanzaste el tiempo", "límite de espera");
      } else {
        setIsLoadingModal(false);
        setShowAlert(true);
        setShowtext("Comprueba", "tu conexión");
      }
      setIsLoading(false);
    }
  };
  const scrollTargetRef = useRef(null);

  useEffect(() => {
    if (scrollTargetRef.current) {
      scrollTargetRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [termsConditionShow, authorizationShow]);

  // Function for authorization and navigate to report
  const authorizeHandler = async () => {
    setIsButtonClicked1(true);
    setSpinnerVisibility(true);
    var dataAutSBPP = {
      oid: dataCurpZero["sujeto_id"], // _id en coleccion verificacion_legal
    };
    const response = await serverRequest2(
      "post",
      "autorizar_ppsb",
      dataAutSBPP
    );
    if (response.data == "validado") {
      if (nss1Data && nssData && estadoData && curpData && personaPresente) {
        setHomonymsmodal(true);
        handleClose();
        return;
      }
      try {
        const cupon =
          guestUser === true ? null : await checkCupons("SB", cookieList[3]);
        if (cupon == "error") {
          setShowAlert(true);
          setShowtext("Tus cupones CB son insuficientes");
          setSpinnerVisibility(false);
        } else {
          var resultados = {};
          if (guestUser) {
            resultados = await validateCupons(
              cookieList[3],
              cookieList[4],
              nbData,
              dataCurpZero["vl_id"]
            );
            if (
              resultados ===
              "Coupon attempts have been exhausted, please try another"
            ) {
              setShowAlert(true);
              setShowtext(
                "Coupon attempts have been exhausted, please try another"
              );
              handleClose();
              setSpinnerVisibility(false);
              return;
            } else if (resultados === "Connection Error") {
              setShowAlert(true);
              setShowtext("Connection Error");
              handleClose();
              setSpinnerVisibility(false);
              return;
            }
          } else {
            // await userCupons(cupon, cookieList[3]);
            resultados = await getReport(
              "SB",
              cupon,
              nbData,
              guestUser,
              undefined,
              dataCurpZero["vl_id"]
            );
            checkCupons("SB", nbData.email);
            // await imprimir(resultados, nbData.email, subsections_dicts, cupon, guestUser);
          }
          if (personaPresente) {
            const reportPage = guestUser ? "/GuestReport" : "/report";
            const personalization = await getPersonalization(
              cupon == 'SB' || cupon == 'CB'? cupon : 'SB', guestUser? 'guestUserInfo' : 'userInfo'
            );
            await verReport(dataCurpZero["sujeto_id"]);
            setSpinnerVisibility(false);
            navigate(reportPage, {
              state: {
                personalization: personalization,
                espData: processCompanyData(cookieList[13] || "", resultados),
                sub_sections_data: subsections_dicts,
                vlid: dataCurpZero["sujeto_id"],
                curp: resultados["CURP"],
                usuarioCupon: "SB",
              },
            });
          } else {
            setSpinnerVisibility(false);
            sendMailAndEmitThankyouMessage(resultados);
          }
        }
      } catch (e) {
        setSpinnerVisibility(false);
        handleClose(); //close the modal
        handleResponseError(e);
      }
    }
  };
  const acceptHandler = () => {
    setauthorizationShow(true);
    setIsButtonClicked(true);
  };

  return (
    <Fragment>
      {/* <Button
      sx={{
        backgroundColor:"primary.main",
        textAlign:"center",
        borderWidth:1,
        borderColor:"primary.main",
        borderStyle:"solid",
        borderRadius:"6px",
        py:1.5,
        px:8,
        color:"#fff",
        textDecoration:"none",
        fontSize:18,
        fontFamily:"Gilroy-Medium",
        textTransform:"capitalize"
     }}
       onClick={handleOpen} variant="contained">Consult</Button> */}

      {showAlert && <AlertModal text={showtext} onClose={handleCloseAlert} />}
      <LogoModalLoader isOpenLogoModal={isLoadingModal} />
      <CustomSpinner visible={spinnerVisibility} />
      {showHomonymsmodal && (
        <HomonymsModal
          guestUser={guestUser}
          edad={edad}
          cname={cname}
          nss1Data={nss1Data}
          nssData={nssData}
          personaPresente={personaPresente}
          estadoData={estadoData}
          curpData={curpData}
          handleclosed={handleHomonymsmodal}
          nbData={nbData}
          subsections_dicts={subsections_dicts}
          dataCurpZero={dataCurpZero}
        />
      )}
      <Modal
        open={props.withoutBureauModalOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        // style={{ overflow: "scroll" }}
      >
        <Box sx={style}>
          <Box
            sx={{
              position: "fixed",
              top: 0,
              right: 0,
              height: "100%",
              overflow: "auto",
            }}
          >
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={{ height: "100%", overflow: "auto" }}>
            {tagShow ? (
              <Typography
                sx={{
                  fontSize: 16,
                  fontFamily: "Gilroy-Medium",
                  color: "rgba(27, 32, 44, 0.6)",
                  textAlign: "center",
                  mb: 4,
                }}
              >
                {tagLine}
              </Typography>
            ) : null}
            <Box
              sx={{
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: "rgba(27, 32, 44, 0.1)",
                borderRadius: "6px",
                p: 2,
              }}
            >
              <Box>
                <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                  <Typography
                    sx={{
                      color: "neutral.900",
                      fontSize: 16,
                      fontFamily: "Gilroy-Medium",
                      textAlign: "center",
                    }}
                  >
                    {tagShow ? emailLabel : t("thanks.Email receipt")}
                  </Typography>
                </Box>
                <Box
                  sx={{ width: { xs: "90%", sm: "70%" }, mx: "auto", pt: 2 }}
                >
                  <LabelInputLeftIcon
                    mailIcon={mailImage}
                    type="text"
                    label=""
                    name="emailAddress"
                    id="emailAddress"
                    inputValue={subjectEmail}
                    disabled={!tagShow}
                    placeholder={t("placeholders.email")}
                    onChange={handleInputEmailChange}
                  />
                </Box>
              </Box>
              {subjectCheckboxShow ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 3,
                  }}
                >
                  {tagShow ? (
                    <FormCheckboxes
                      topLabel={false}
                      sx_radio={{ padding: "3px 9px" }}
                      reasonsGrouping={subjectList}
                      reasonSelect={
                        guestUser
                          ? "The present subject is found"
                          : subjectFoundSelect
                      }
                      onChange={subjectFoundHandler}
                    />
                  ) : null}
                </Box>
              ) : null}
              {emailBoxButtonShow ? (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      mt: 4,
                    }}
                  >
                    <Button
                      disabled={!emailButtonShow || isLoading}
                      onClick={sendHandler}
                      sx={{
                        backgroundColor: "primary.main",
                        py: 1,
                        px: { xs: 4, sm: 8 },
                        fontSize: "18px",
                        fontFamily: "Gilroy-Medium",
                        textTransform: "capitalize",
                      }}
                      variant="contained"
                    >
                      {emailSent ? t("buttons.Resend") : t("buttons.Send")}
                    </Button>
                  </Box>
                  {tagShow ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        mt: 4,
                      }}
                    >
                      <Button
                        onClick={handleClose}
                        style={{
                          color: "rgba(27, 32, 44, 0.6)",
                          fontSize: 16,
                          fontFamily: "Gilroy-Medium",
                          textDecoration: "underline",
                          textTransform: "capitalize",
                        }}
                        variant="text"
                      >
                        {t("buttons.back")}
                      </Button>
                    </Box>
                  ) : null}
                </>
              ) : null}
            </Box>

            {termsConditionShow ? (
              <>
                <div ref={scrollTargetRef}>
                  <Box sx={{ my: 4 }}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isCheckboxChecked}
                            onChange={handleCheckboxChange}
                          />
                        }
                        label={
                          <Box component={"span"} sx={{ color: "#4165F6" }}>
                            {t("guestTermsCondition.accept")}
                            <Box
                              component={"span"}
                              sx={{ color: "gray", ml: 1 }}
                            >
                              <a
                                href="/PrivacyNotice"
                                target={"_blank"}
                                style={{ color: "#656DFC" }}
                              >
                                {`${t("guestTermsCondition.Privacy Notice")} `}
                              </a>
                              &
                              <a
                                href="/TermsAndConditions"
                                target={"_blank"}
                                style={{ color: "#656DFC" }}
                              >
                                {` ${t(
                                  "guestTermsCondition.Terms and Conditions"
                                )}`}
                              </a>
                              {` ${t("guestTermsCondition.newTerm1")}`}
                              {` ${t("guestTermsCondition.newTerm2")} `}
                              <a
                                href="https://micuenta.infonavit.org.mx/"
                                target={"_blank"}
                                style={{ color: "#656DFC" }}
                                rel="noreferrer"
                              >
                                Infonavit
                              </a>
                              {` ${t("guestTermsCondition.newTerm3")} `}
                              <a
                                href="https://serviciosdigitales.imss.gob.mx/semanascotizadas-web/"
                                target={"_blank"}
                                style={{ color: "#656DFC" }}
                                rel="noreferrer"
                              >
                                IMSS
                              </a>
                              {` ${t("guestTermsCondition.newTerm4")} `}
                            </Box>
                          </Box>
                        }
                      />
                    </FormGroup>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        mt: 4,
                      }}
                    >
                      <Button
                        onClick={acceptHandler}
                        sx={{
                          backgroundColor: "primary.main",
                          py: 1,
                          px: { xs: 4, sm: 8 },
                          fontSize: "18px",
                          fontFamily: "Gilroy-Medium",
                          textTransform: "capitalize",
                        }}
                        variant="contained"
                        disabled={!isCheckboxChecked || isButtonClicked}
                      >
                        {t("buttons.Accept")}
                      </Button>
                    </Box>
                  </Box>
                </div>
              </>
            ) : null}
            {authorizationShow ? (
              <>
                <div ref={scrollTargetRef}>
                  <hr
                    style={{
                      background: "rgba(255, 255, 255, 0.2)",
                      borderColor: "rgba(255, 255, 255, 0.2)",
                    }}
                  />
                  <Box sx={{ mt: 2, mb: 2 }}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            defaultChecked={isCheckboxCheckedSecond}
                            onChange={(e) =>
                              setIsCheckboxCheckedSecond(e.target.checked)
                            }
                          />
                        }
                        label={
                          <Box component={"span"} sx={{ color: "#4165F6" }}>
                            {t("guestTermsCondition.authorization")}{" "}
                            <Box
                              component={"span"}
                              sx={{ color: "gray", ml: 1 }}
                            >
                              {t("guestTermsCondition.authorization1")}
                            </Box>
                          </Box>
                        }
                      />
                    </FormGroup>
                    <Typography
                      sx={{
                        fontSize: 16,
                        color: "text.opacity",
                        fontFamily: "Gilroy-Medium",
                        mt: 4,
                      }}
                    >
                      {t("guestTermsCondition.authorization2")}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      mt: 4,
                    }}
                  >
                    <Button
                      onClick={authorizeHandler}
                      sx={{
                        backgroundColor: "primary.main",
                        py: 1,
                        px: { xs: 4, sm: 8 },
                        fontSize: "18px",
                        fontFamily: "Gilroy-Medium",
                        textTransform: "capitalize",
                      }}
                      variant="contained"
                      disabled={!isCheckboxCheckedSecond || isButtonClicked1}
                    >
                      {t("buttons.Authorize")}
                    </Button>
                  </Box>
                </div>
              </>
            ) : null}
          </Box>
        </Box>
      </Modal>
    </Fragment>
  );
}
